import { Stack } from '@mui/material';
import { graphql, useFragment } from 'react-relay';

import type { UserMenuAccounts_user$key } from './__generated__/UserMenuAccounts_user.graphql.ts';
import UserMenuAccountItem from './UserMenuAccountItem.tsx';

import type { ReactNode } from 'react';

export type UserMenuAccountsProps = {
  user: UserMenuAccounts_user$key;
};

const UserMenuAccounts = (props: UserMenuAccountsProps): ReactNode => {
  const user = useFragment<UserMenuAccounts_user$key>(
    graphql`
      fragment UserMenuAccounts_user on User
      @argumentDefinitions(first: { type: "Int", defaultValue: 50 }) {
        company {
          companyBankAccounts(first: $first) {
            edges {
              node {
                id
                ...UserMenuAccountItem_account
              }
            }
          }
          defaultCompanyBankAccount {
            id
          }
        }
        ...UserMenuAccountItem_user
      }
    `,
    props.user,
  );

  const selectedValue = user?.company?.defaultCompanyBankAccount?.id;

  if (!selectedValue) {
    return null;
  }

  return (
    <Stack sx={{ p: 1 }}>
      {user?.company?.companyBankAccounts.edges.map((edge) => {
        if (!edge) {
          return null;
        }

        return (
          <UserMenuAccountItem
            key={edge?.node?.id}
            account={edge.node!}
            user={user}
            selectedValue={selectedValue}
          />
        );
      })}
    </Stack>
  );
};

export default UserMenuAccounts;
