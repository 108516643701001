import { Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { shouldUseSentry } from '@woovi/shared';

import Card from '../card/Card.tsx';

const ContentError = ({ error }): ReactNode => {
  const { t } = useTranslation();

  if (process.env.NODE_ENV === 'staging') {
    return (
      <Card>
        <Typography>{t('Error rendering')}</Typography>
        <Typography>
          <strong>Error:</strong> {error.toString()}
        </Typography>
      </Card>
    );
  }

  return (
    <Card>
      <Typography>{t('Oops! an error has occurred')}</Typography>
    </Card>
  );
};

const ContentBoundary = ({ children }): ReactNode => {
  if (shouldUseSentry) {
    return (
      <Sentry.ErrorBoundary fallback={ContentError}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ContentError}>{children}</ErrorBoundary>
  );
};

export default ContentBoundary;
