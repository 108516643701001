import AdminApp from '../../../components/root/AdminApp.tsx';
import type { RouteType } from '../../utils/index.tsx';
import { cashbackRoutes } from '../cashbackRoutes.tsx';
import { clickpixRoutes } from '../clickpixRoutes.tsx';
import apiPluginsRoutes from '../core/apiPluginsRoutes.tsx';
import permissionsRoutes from '../core/permissionsRoutes.tsx';
import settingsRoutes from '../core/settingsRoutes.tsx';
import { crmRoutes } from '../crmRoutes.tsx';
import homeRoutes from '../homeRoutes.tsx';
import { integrationNfeioRoutes } from '../integrationNfeioRoutes.tsx';
import { invoiceRoutes } from '../invoiceRoutes.tsx';
import accountsRoutes from '../openpix/accountsRoutes.tsx';
import { openpixRoutes } from '../openpix/openpixRoutes.tsx';
import { partnerRoutes } from '../partnerRoutes.tsx';
import { pixMachineRoutes } from '../pixMachineRoutes.tsx';
import areasRoutes from '../planning/areasRoutes.tsx';
import { rewardsRoutes } from '../rewardsRoutes.tsx';
import routeHandlers from '../routeHandlers.tsx';
import searchRoutes from '../searchRoutes.tsx';
import meRoutes from '../user/meRoutes.tsx';

const PATH = '/home';

const rootRoutes: RouteType = {
  name: 'rootRoutes',
  path: PATH,
  exact: false,
  component: AdminApp,
  routes: [
    ...settingsRoutes,
    ...meRoutes,
    ...permissionsRoutes,
    ...homeRoutes,
    ...openpixRoutes,
    ...cashbackRoutes,
    ...crmRoutes,
    ...rewardsRoutes,
    ...apiPluginsRoutes,
    ...accountsRoutes,
    ...areasRoutes,
    ...partnerRoutes,
    ...searchRoutes,
    ...pixMachineRoutes,
    ...integrationNfeioRoutes,
    ...clickpixRoutes,
    ...invoiceRoutes,
    ...routeHandlers(PATH),
  ],
};

export default rootRoutes;
