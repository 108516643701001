import { ExpandMore } from '@mui/icons-material';
import { Typography } from '@mui/material';
import type { Theme } from '@mui/material';
import _Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatToCPFOrCNPJ } from 'brazilian-values';
import { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import styled from 'styled-components';

import { Avatar, BoxFlex } from '@woovi/ui';

import type { User_me$key } from './__generated__/User_me.graphql.ts';
import { UserMenu } from './UserMenu.tsx';

const Button = styled(_Button)`
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 4px;
  gap: 4px;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

type Props = {
  me: User_me$key;
};
const User = (props: Props) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const me = useFragment<User_me$key>(
    graphql`
      fragment User_me on User {
        ...UserMenu_me
        name
        company {
          name
          taxID {
            taxID
          }
        }
        profileImage {
          url
        }
        userMaster {
          name
        }
      }
    `,
    props.me,
  );

  if (!me) {
    return null;
  }

  const { profileImage } = me;
  const profileImageUrl = profileImage ? profileImage.url : null;

  const getName = () =>
    me.company?.name.length > 20
      ? `${me.company?.name?.slice(0, 20)}...`
      : me.company?.name;

  return (
    <>
      <Button
        onClick={handleClick}
        variant='text'
        sx={{ ml: '12px', mr: '20px' }}
      >
        <ExpandMore />
        {isDesktop && (
          <BoxFlex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              px: '8px',
            }}
          >
            <Typography fontSize={12} fontWeight={600}>
              {getName()}
            </Typography>
            <Typography fontSize={12}>
              {me.company?.taxID?.taxID &&
                formatToCPFOrCNPJ(me.company.taxID?.taxID)}
            </Typography>
          </BoxFlex>
        )}
        <Avatar
          name={me?.userMaster?.name || ''}
          size={36}
          imageUrl={profileImageUrl}
        />
      </Button>
      <UserMenu me={me} open={open} onClose={handleClose} anchorEl={anchorEl} />
    </>
  );
};

export default User;
