import { ChevronRight } from '@mui/icons-material';
import { Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'react-relay';

import { ActionButton } from '@woovi/ui';

import type { UserMenuAuths_user$key } from './__generated__/UserMenuAuths_user.graphql.ts';
import { UserMenuAuthItem } from './UserMenuAuthItem.tsx';

type UserMenuAuthsProps = {
  user: UserMenuAuths_user$key;
};

export const UserMenuAuths = (props: UserMenuAuthsProps): ReactNode => {
  const { t } = useTranslation();

  const user = useFragment<UserMenuAuths_user$key>(
    graphql`
      fragment UserMenuAuths_user on User
      @argumentDefinitions(first: { type: "Int", defaultValue: 50 }) {
        userMaster {
          companies(first: $first) {
            edges {
              node {
                id
                ...UserMenuAuthItem_company
              }
            }
          }
        }
        ...UserMenuAuthItem_user
      }
    `,
    props.user,
  );

  const { userMaster } = user;

  const handleClick = (): void => {
    window.location.href = '/login/user/company/create';
  };

  const handleClickMyInvites = (): void => {
    window.location.href = '/login/user/companies';
  };

  return (
    <Stack sx={{ p: 1 }}>
      {userMaster?.companies?.edges.map((edge) => {
        return (
          edge?.node && (
            <UserMenuAuthItem
              key={edge.node.id}
              company={edge.node}
              user={user}
            />
          )
        );
      })}
      <ActionButton
        variant='text'
        color='secondary'
        onClick={handleClick}
        sx={{ justifyContent: 'space-between' }}
      >
        <Stack alignItems={'start'}>{t('Create company')}</Stack>
        <ChevronRight />
      </ActionButton>
      <ActionButton
        variant='text'
        color='secondary'
        onClick={handleClickMyInvites}
        sx={{ justifyContent: 'space-between' }}
      >
        <Stack alignItems={'start'}>{t('My invites')}</Stack>
        <ChevronRight />
      </ActionButton>
    </Stack>
  );
};
