import { useTheme } from '@mui/material/styles';
import type { ReactNode } from 'react';

import BoxFlex from '../mui/BoxFlex.tsx';

export type FieldRowProps = {
  children: ReactNode;
};

const FieldRow = ({ children }: FieldRowProps): ReactNode => {
  const theme = useTheme();

  return (
    <BoxFlex
      sx={{
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          gap: 2,
        },
      }}
    >
      {children}
    </BoxFlex>
  );
};

export default FieldRow;
