import { useField } from 'formik';
import * as React from 'react';

import type { TaxIDInputProps } from './TaxIDInput.tsx';
import TaxIDInput from './TaxIDInput.tsx';

import type { ReactNode } from 'react';

export type TaxIDInputFormikProps = {
  name: string;
} & TaxIDInputProps;
const TaxIDInputFormik = (props: TaxIDInputFormikProps): ReactNode => {
  const { name, ...rest } = props;

  const [field, meta] = useField(name);

  const inputProps = {
    'data-testid': name,
  };

  const hasError = meta?.touched;

  const getErrorProps = () => {
    if (!hasError) {
      return {};
    }

    if (typeof meta?.error === 'string') {
      return {
        error: !!meta?.error,
        helperText: meta?.error,
      };
    }

    return {};
  };

  const errorProps = getErrorProps();

  return (
    <TaxIDInput {...field} inputProps={inputProps} {...errorProps} {...rest} />
  );
};

export default TaxIDInputFormik;
