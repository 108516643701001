import * as Sentry from '@sentry/react';

import { shouldUseSentry } from '@woovi/shared';

export const logOut = (): void => {
  const getHref = (): void => {
    const location = window.location;

    if (
      !location.pathname ||
      location.pathname === '/' ||
      location.pathname.includes('logout')
    ) {
      window.location.href = '/login?invalidSession=true';

      return;
    }

    const urlParams = new URLSearchParams();

    urlParams.set('next', location.pathname + location.search);

    window.location.href = `/login?${urlParams.toString()}&invalidSession=true`;
  };

  if (shouldUseSentry) {
    Sentry.getCurrentScope()?.setUser(null);
  }

  if (window?.$chatwoot) {
    window.$chatwoot.reset();
  }

  getHref();
};

export const tokenLogOut = (): void => {
  logOut();
};
