import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useHistory } from 'react-router';

import { TableInfiniteScroll } from '@woovi/components';
import { withRelayBoundary } from '@woovi/relay';
import type { ExtractNode, FetchKeyProp } from '@woovi/relay';
import { ChargeTagStatus, Tag } from '@woovi/ui';
import { chargeStatusTypeI18n } from '@woovi/utils';

import type { ChargesQuickViewListQuery } from './__generated__/ChargesQuickViewListQuery.graphql.ts';
import type {
  ChargesQuickViewListQuery_charge$data,
  ChargesQuickViewListQuery_charge$key,
} from './__generated__/ChargesQuickViewListQuery_charge.graphql.ts';
import { routeToMain } from '../../../router/utils/index.tsx';
import { useChargeCreatedSubscription } from '../../charge/subscription/useChargeCreatedSubscription.ts';
import { useChargeUpdatedStatusSubscription } from '../../charge/subscription/useChargeUpdatedStatusSubscription.tsx';

import type { ReactNode } from 'react';

type ChargeQuickViewNodeType = ExtractNode<
  ChargesQuickViewListQuery_charge$data['charges']
>;

type ChargesQuickViewListProps = FetchKeyProp;

const ChargesQuickViewList = ({
  fetchKey,
}: ChargesQuickViewListProps): ReactNode => {
  const { t } = useTranslation();
  const history = useHistory();

  const response = useLazyLoadQuery<ChargesQuickViewListQuery>(
    graphql`
      query ChargesQuickViewListQuery($first: Int, $after: String) {
        ...ChargesQuickViewListQuery_charge
          @arguments(first: $first, after: $after)
      }
    `,
    {},
    {
      fetchPolicy: 'store-and-network',
      fetchKey,
    },
  );

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    ChargesQuickViewListQuery,
    ChargesQuickViewListQuery_charge$key
  >(
    graphql`
      fragment ChargesQuickViewListQuery_charge on Query
      @refetchable(queryName: "ChargesQuickViewListRefetchQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String", defaultValue: null }
        last: { type: "Int", defaultValue: null }
        before: { type: "String", defaultValue: null }
      ) {
        charges(first: $first, after: $after, last: $last, before: $before)
          @connection(key: "ChargesQuickViewList_charges", filters: []) {
          __id
          endCursorOffset
          startCursorOffset
          count
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              id
              customer {
                name
              }
              value
              status
              additionalInfo {
                key
                value
              }
            }
          }
        }
      }
    `,
    response,
  );

  const { charges } = data;

  const onRowClick = (row) => {
    const route = routeToMain('charge.data', { id: row.id });

    history.push(route);
  };

  useChargeCreatedSubscription({
    input: {},
    connections: [charges.__id],
  });

  useChargeUpdatedStatusSubscription({
    input: {},
  });

  const columns = useMemo<GridColDef<ChargeQuickViewNodeType>[]>(
    () => [
      {
        flex: 1,
        headerName: t('Customer'),
        valueGetter: ({ row }) => row?.customer?.name,
        field: 'name',
      },
      {
        headerName: t('Status'),
        field: 'status',
        headerAlign: 'center',
        align: 'center',
        resizable: false,
        renderCell: ({ value: status }) => {
          return (
            <ChargeTagStatus
              label={chargeStatusTypeI18n(t, status)}
              variant={'default'}
              status={status}
            />
          );
        },
      },
      {
        headerName: t('Value'),
        field: 'value',
        type: 'currency',
        minWidth: 140,
      },
      {
        headerName: t('Tags'),
        field: 'tags',
        renderCell: ({ row: charge }) => {
          if (!charge?.additionalInfo || charge?.additionalInfo?.length === 0) {
            return '-';
          }

          return charge.additionalInfo.map((node, key) => (
            <Tag key={key} value={node?.value} label={node?.key} />
          ));
        },
      },
    ],
    [],
  );

  return (
    <TableInfiniteScroll
      cardListDefaultHeight='100%'
      tableName='ChargeQuickViewList'
      columns={columns}
      connection={charges}
      pagination={{ hasNext, loadNext: () => loadNext(30) }}
      isLoadingNext={isLoadingNext}
      displayCardList
      onRowClick={({ row }) => onRowClick(row)}
    />
  );
};

export default withRelayBoundary(ChargesQuickViewList);
