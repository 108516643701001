import { schemaFieldIsRequired } from './schemaFieldIsRequired.ts';

export const schemaObjectHasRequiredField = (field) => {
  if (!field) {
    return false;
  }

  const objectFields = field.describe().fields;

  for (const childField in objectFields) {
    const isRequired = schemaFieldIsRequired(objectFields[childField]);

    if (isRequired) {
      return true;
    }
  }

  return false;
};
