import Button from '@mui/material/Button';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import ErrorBoundaryWithRetry from './ErrorBoundaryWithRetry.tsx';
import Card from '../card/Card.tsx';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const Typography = styled.p`
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
`;

type Props = {
  error: string;
  retry?: () => void;
};
const ErrorView = ({ error, retry = undefined }: Props): ReactNode => {
  const getErrorMessage = () => {
    if (Array.isArray(error)) {
      return error[0]?.message;
    }

    return error?.message || error;
  };

  const errorMessage = getErrorMessage();

  return (
    <Wrapper>
      <Container>
        <Card m='20px' p='20px' flexDirection='column' alignItems='center'>
          <h3>Ops! Ocorreu um erro</h3>
          <Typography>{errorMessage}</Typography>
          {retry && (
            <Button variant='outlined' onClick={retry}>
              Tentar novamente
            </Button>
          )}
        </Card>
      </Container>
    </Wrapper>
  );
};

export const ErrorViewBoundary = ({ children }): ReactNode => {
  return (
    <ErrorBoundaryWithRetry
      fallback={(error, retry) => <ErrorView error={error} retry={retry} />}
    >
      {({ fetchKey }) => children({ fetchKey })}
    </ErrorBoundaryWithRetry>
  );
};

export default ErrorView;
