import { ChevronRight, ExpandMore } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import { lighten, useTheme } from '@mui/material/styles';
import { ptBR, enUS, esES, useGridApiRef } from '@mui/x-data-grid-pro';
import type {
  DataGridProProps as MUIDataGridProProps,
  GridExperimentalProFeatures,
  GridEventListener,
} from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { useMemo, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelected } from '@woovi/shared';
import { columnTypes } from './columnTypes.ts';
import { DataGridToolbarExport } from './DataGridGridToolbarExport.tsx';
import DataGridRow from './DataGridRow.tsx';
import { wrapColumnTypes } from './wrapColumnTypes.ts';
import type { ReactNode } from 'react';
export const GridDensityTypes = {
  Compact: 'compact',
  Standard: 'standard',
  Comfortable: 'comfortable',
} as const;
export type GridDensityTypes =
  (typeof GridDensityTypes)[keyof typeof GridDensityTypes];
export type DataGridProps = MUIDataGridProProps & {
  maxLines?: number;
  rowHref?: string;
  // @TODO: We should improve how we get this key, maybe getting the displayName of the component that is using the DataGrid
  tableName?: string;
  disableSelectAllCheckbox?: boolean;
  experimentalFeatures?: GridExperimentalProFeatures & {
    rowAnchor?: boolean;
    zebraRow?: boolean;
    persistState?: boolean;
    exportTable?: boolean;
  };
  exportBeta?: boolean;
};
const EMPTY_HEIGHT = 65;
// is necessary to disable virtualization of datagrid while testing to render
// all columns from datagrid
const isRunningOnJest = process.env.JEST_WORKER_ID !== undefined;
const DataGrid = ({
  columns: _columns,
  rows,
  onRowClick,
  maxLines,
  rowHref,
  experimentalFeatures,
  exportBeta = true,
  initialState = { columns: { columnVisibilityModel: {} } },
  ...rest
}: DataGridProps): ReactNode => {
  const theme = useTheme();
  const apiRef = useGridApiRef();
  const [t, i18n] = useTranslation();
  const localeText = useMemo(() => {
    if (i18n.language === 'pt-BR') {
      return ptBR.components.MuiDataGrid.defaultProps.localeText;
    }
    if (i18n.language === 'es-ES') {
      return esES.components.MuiDataGrid.defaultProps.localeText;
    }
    return enUS.components.MuiDataGrid.defaultProps.localeText;
  }, []);
  const [height, setHeight] = useState<string | number | null>(
    'calc(100vh - 210px)',
  );
  const getHeaderHeight = () => {
    if (rest.density === GridDensityTypes.Compact) {
      return 56;
    }
    if (rest.density === GridDensityTypes.Comfortable) {
      return 90;
    }
    if (rest.density === GridDensityTypes.Standard) {
      return 74;
    }
    return 74;
  };
  const onStateChange: GridEventListener<'stateChange'> = (state) => {
    const headerHeight = getHeaderHeight();
    if (experimentalFeatures?.persistState) {
      if (!rest.tableName) {
        throw new Error('tableName is required when persistState is true');
      }
      localStorage.setItem(
        `@datagrid/${rest.tableName}`,
        JSON.stringify(apiRef.current.exportState()),
      );
    }
    if (maxLines) {
      if (state.rowsMeta?.positions?.length > maxLines + 1) {
        const itemsHeight = state.rowsMeta.positions[maxLines + 1];
        setHeight(itemsHeight);
        return;
      }
    }
    if (state.rows.totalRowCount === 0) {
      setHeight(headerHeight + EMPTY_HEIGHT);
      return;
    }
    // if the screen is smaller it does not work well
    const fewerItems = state.rows.totalRowCount < 20;
    if (fewerItems) {
      setHeight(null);
      return;
    }
    setHeight('calc(100vh - 210px)');
  };
  const onTableRowClick: DataGridProps['onRowClick'] = (
    params,
    event,
    details,
  ) => {
    if (!onRowClick) {
      return;
    }
    if (getSelected().length > 0) {
      return;
    }
    return onRowClick(params, event, details);
  };
  const getDataGridRowProps = () => {
    if (!rowHref) {
      return {};
    }
    return {
      href: rowHref,
    };
  };
  const getRowStyle = () => {
    if (!experimentalFeatures?.zebraRow) {
      return;
    }
    if (experimentalFeatures.rowAnchor) {
      return {
        '& .MuiDataGrid-rowLink:nth-child(odd)': {
          backgroundColor: lighten(theme.palette.primary.main, 0.9),
        },
      };
    }
    return {
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
      },
    };
  };
  useLayoutEffect(() => {
    if (!experimentalFeatures?.persistState) {
      return;
    }
    if (!rest.tableName) {
      throw new Error('tableName is required when persistState is true');
    }
    const state = JSON.parse(
      localStorage.getItem(`@datagrid/${rest.tableName}`) || null,
    );
    if (!state) {
      return;
    }
    apiRef.current.restoreState(state);
  }, []);
  // Allow to disable the select all checkbox on header row.
  // The purpose of this edge case is to use it where we can select only one checkbox, but
  // the header checkbox still available and selecting all rows.
  // See more here: https://github.com/mui/mui-x/issues/1904#issuecomment-1219293315
  const getDisableSelectAllCheckbox = () => {
    if (!rest.checkboxSelection) {
      return {};
    }
    if (!rest.disableSelectAllCheckbox) {
      return {};
    }
    return {
      '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
        {
          display: 'none',
        },
    };
  };
  const columns = wrapColumnTypes(_columns);
  const getToolbarSlot = () => {
    if (experimentalFeatures?.exportTable) {
      return DataGridToolbarExport;
    }
    if (process.env.ENTRYPOINT === 'CONSOLE') {
      return DataGridToolbarExport;
    }
    if (!exportBeta) {
      return null;
    }
    if (process.env.NODE_ENV === 'production') {
      return null;
    }
    return DataGridToolbarExport;
  };
  return (
    <Paper sx={{ height }} elevation={0}>
      <DataGridPro
        apiRef={apiRef}
        slots={{
          detailPanelExpandIcon: ChevronRight,
          detailPanelCollapseIcon: ExpandMore,
          row: DataGridRow,
          // @TODO: Handle it with TEMP companies.
          toolbar: getToolbarSlot(),
        }}
        columns={columns}
        columnTypes={columnTypes}
        columnBuffer={4}
        columnThreshold={4}
        rows={rows}
        hideFooter
        disableRowSelectionOnClick
        onStateChange={onStateChange}
        sx={(theme) => ({
          ...getRowStyle(),
          ...getDisableSelectAllCheckbox(),
          '& .MuiDataGrid-cell': {
            flexWrap: 'wrap',
            gap: theme.spacing(0.5),
            py: theme.spacing(0.5),
            overflowWrap: 'anywhere',
          },
        })}
        density={'compact'}
        onRowClick={onTableRowClick}
        localeText={{
          ...localeText,
          // @TODO: Hardcoded this locale text until fix the tests conflicting between 2+ Export buttons nodes at the same DOM
          toolbarExport: t('Export via Datagrid (BETA)'),
        }}
        autoHeight={!height}
        disableVirtualization={isRunningOnJest}
        {...rest}
        slotProps={{
          row: getDataGridRowProps(),
        }}
        experimentalFeatures={experimentalFeatures}
        initialState={initialState}
      />
    </Paper>
  );
};
export default DataGrid;
