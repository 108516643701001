import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

import { applicationRoutes } from './applicationRoutes.tsx';
import type { RouteType } from '../../utils/index.tsx';

export const EXPORT_ROUTE_MODULES = [
  MODULES.OPEN_PIX,
  MODULES.PLANNING,
  MODULES.PERMISSIONS,
];

const PATH = '/home/settings';

const companyRoutes: RouteType[] = [
  {
    name: 'settings',
    path: `${PATH}/tabs`,
    component: loadable(
      () => import('../../../components/admin/settings/SettingsHeader.tsx'),
    ),
    labelHeader: ({ t }) => t('Settings'),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.CORE.LOGIN],
    requiredFeatures: [MODULES.ADJUST, MODULES.CORE],
    routes: [
      {
        name: 'settings.data',
        path: `${PATH}/tabs/data`,
        component: loadable(
          () => import('../../../components/company/data/CompanyData.tsx'),
        ),
        exact: false,
        labelHeader: ({ t }) => t('Company'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.ADJUST],
        label: ({ t }) => t('Data'),
      },
      {
        name: 'settings.openpix',
        path: `${PATH}/tabs/pix`,
        labelHeader: ({ t }) => t('Pix'),
        label: ({ t }) => t('Pix'),
        component: loadable(
          () => import('../../../components/admin/settings/OpenPixSettings.tsx'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'settings.my.plan',
        path: `${PATH}/tabs/my-plan`,
        labelHeader: ({ t }) => t('My plan'),
        label: ({ t }) => t('My plan'),
        component: loadable(
          () => import('../../../components/platformPlan/PlatformPlanDetail.tsx'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'settings.email.preview',
        path: `${PATH}/tabs/emailPreview`,
        component: loadable(
          () => import('../../../components/email/EmailPreview.tsx'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Company'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.TEMP],
        label: ({ t }) => t('Email Preview'),
      },
    ],
  },
  ...applicationRoutes,
  {
    name: 'settings.companyBankAccount',
    path: `${PATH}/company-bank-acccount`,
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple.tsx'),
    ),
    labelHeader: ({ t }) => t('Advanced Settings'),
    exact: false,
    requiredRoles: [ROLES.OPEN_PIX.ALL, ADMIN_ROLE],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'settings.companyBankAccount.list',
        path: `${PATH}/company-bank-acccount/list`,
        labelHeader: ({ t }) => t('Imports'),
        component: loadable(
          () =>
            import(
              '../../../components/admin/settings/companyBankAccount/CompanyBankAccountPixKeyList.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.ADJUST],
      },
    ],
  },
];

export default companyRoutes;
