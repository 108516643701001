import { AttachMoney } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BoxFlex, WindowPopper } from '@woovi/ui';

import ChargesQuickViewList from './ChargesQuickViewList.tsx';

import type { ReactNode } from 'react';

export const ChargeQuickViewWindowPopper = (): ReactNode => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BoxFlex>
      <IconButton sx={{ color: 'white' }} onClick={handleOpen}>
        <AttachMoney />
      </IconButton>
      <WindowPopper
        title={t('Recent Charges')}
        open={open}
        onClose={handleClose}
        cancelDragOnContent
        y={40}
        x={-250}
      >
        <ChargesQuickViewList />
      </WindowPopper>
    </BoxFlex>
  );
};
