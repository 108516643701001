import * as firebase from 'firebase/app';
import type { FirebaseApp } from 'firebase/app';
import * as firebaseMessaging from 'firebase/messaging';
import { useEffect, useRef } from 'react';
import { useMutation } from 'react-relay';

import type { PushTokenAddMutation } from './__generated__/PushTokenAddMutation.graphql.ts';
import { firebaseConfig } from './firebaseConfig.tsx';
import { PushTokenAdd, USER_PUSHENDPOINT_TYPE } from './PushTokenAddMutation.tsx';
import config from '../config.tsx';

export const useSWReg = (): void => {
  const onMessage = useRef<firebase.Unsubscribe>(null);

  const [pushTokenAdd] = useMutation<PushTokenAddMutation>(PushTokenAdd);

  useEffect(() => {
    // TODO - use workbox-window
    const registerSW = async (): Promise<void> => {
      if (!firebaseMessaging) {
        // eslint-disable-next-line
        console.log('firebase not supported');

        return;
      }

      const isFirebaseMessagingSupported =
        await firebaseMessaging.isSupported();

      if (!isFirebaseMessagingSupported) {
        // eslint-disable-next-line
        console.log('firebase messaging not supported');

        return;
      }

      if ('serviceWorker' in navigator) {
        const registration =
          await navigator.serviceWorker.getRegistration('/home/sw.js');

        if (!registration) {
          // eslint-disable-next-line
          console.log('service worker not registered in /home/sw.js');

          return;
        }

        const firebaseApps = firebase.getApps();
        let firebaseApp: FirebaseApp;

        const shouldInitializeApp = firebaseApps.length === 0;

        if (shouldInitializeApp) {
          firebaseApp = firebase.initializeApp(firebaseConfig);
        } else {
          firebaseApp = firebaseApps[0];
        }

        const messaging = firebaseMessaging.getMessaging(firebaseApp);

        try {
          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
            const token = await firebaseMessaging.getToken(messaging, {
              vapidKey: config.FIREBASE_VAPID,
              serviceWorkerRegistration: registration,
            });

            if (!token) {
              // eslint-disable-next-line
              console.log('token not provided');

              return;
            }

            const pushConfig = {
              variables: {
                input: {
                  token,
                  os: USER_PUSHENDPOINT_TYPE.WEB,
                },
              },
            };

            pushTokenAdd(pushConfig);
          }
        } catch (err) {
          if (err.code === 'messaging/permission-blocked') {
            // eslint-disable-next-line
            console.log('Please Unblock Notification Request Manually: ', err);
          } else {
            // eslint-disable-next-line
            console.log('getToken err: ', err);
          }
        }

        onMessage.current = firebaseMessaging.onMessage(
          messaging,
          (payload) => {
            // eslint-disable-next-line
            console.log('notificationData: ', payload);

            const { data, notification } = payload;

            const { title, body } = notification;

            const options = {
              vibrate: [200],
              body,
              data,
              // icon: './img/woovi_logo.png',
            };

            registration.showNotification(title, options);
          },
        );
      }
    };

    registerSW();

    return () => {
      if (onMessage.current) {
        onMessage.current();
      }
    };
  }, []);
};
