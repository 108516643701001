/**
 * @generated SignedSource<<37f934ab87d7538b4fb9a165593a7ee7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAccountItem_account$data = {
  readonly code: string | null | undefined;
  readonly id: string;
  readonly isDefault: boolean;
  readonly name: string | null | undefined;
  readonly status: string | null | undefined;
  readonly type: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuCompanyBankAccountStatus_account" | "UserMenuConfirmDefaultAccountModal_account">;
  readonly " $fragmentType": "UserMenuAccountItem_account";
};
export type UserMenuAccountItem_account$key = {
  readonly " $data"?: UserMenuAccountItem_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccountItem_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAccountItem_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuConfirmDefaultAccountModal_account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuCompanyBankAccountStatus_account"
    }
  ],
  "type": "CompanyBankAccount",
  "abstractKey": null
};

(node as any).hash = "7a71b57b773bcf7c2fa988ee9ce33442";

export default node;
