import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

import type { RouteType } from '../utils/index.tsx';

const partnerDefaultRequiredFeatures = [
  { $and: [MODULES.OPEN_PIX, MODULES.PARTNER] },
];

const partnerDefaultRequiredRoles = [
  ROLES.PARTNER.ALL,
  ROLES.OPEN_PIX.ADMIN.ALL,
  ADMIN_ROLE,
];

const PATH = '/home/partner';

export const partnerRoutes: RouteType[] = [
  {
    name: 'partner.newCompany',
    path: `${PATH}/new-company`,
    component: loadable(
      () =>
        import('../../components/partner/PartnerAddPreRegistrationForm.tsx'),
    ),
    exact: true,
    labelHeader: 'OpenPix',
    label: 'OpenPix',
    requiredRoles: partnerDefaultRequiredRoles,
    requiredFeatures: partnerDefaultRequiredFeatures,
  },
  {
    name: 'partner',
    path: `${PATH}/tab`,
    component: loadable(
      () => import('../../components/partner/PartnerHeader.tsx'),
    ),
    exact: false,
    labelHeader: 'OpenPix',
    requiredRoles: partnerDefaultRequiredRoles,
    requiredFeatures: partnerDefaultRequiredFeatures,
    routes: [
      {
        name: 'partner.company',
        path: `${PATH}/tab/company-list`,
        component: loadable(
          () => import('../../components/partner/PartnerCompanyList.tsx'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Companies'),
        label: ({ t }) => t('Companies'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: partnerDefaultRequiredFeatures,
      },
      {
        name: 'partner.preRegistration',
        path: `${PATH}/tab/preRegistration`,
        component: loadable(
          () =>
            import(
              '../../components/partner/preRegistration/PartnerPreRegistrationList.tsx'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Pre-registrations'),
        label: ({ t }) => t('Pre-registrations'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: partnerDefaultRequiredFeatures,
      },
      {
        name: 'partner.settings',
        path: `${PATH}/tab/settings`,
        component: loadable(
          () => import('../../components/partner/PartnerSettings.tsx'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: [MODULES.SPLIT_PARTNER, MODULES.PARTNER_WALLET],
      },
    ],
  },
  {
    name: 'partner.preRegistration.data',
    path: `${PATH}/preRegistration/:id/data`,
    labelHeader: ({ t }) => t('Companies'),
    label: ({ t }) => t('Companies'),
    component: loadable(
      () =>
        import(
          '../../components/partner/preRegistration/PartnerPreRegistrationDetail.tsx'
        ),
    ),
    exact: true,
    requiredRoles: partnerDefaultRequiredRoles,
    requiredFeatures: partnerDefaultRequiredFeatures,
  },
  {
    name: 'partner.company.details',
    path: `${PATH}/company/details/:id`,
    labelHeader: ({ t }) => t('Details'),
    label: ({ t }) => t('Details'),
    component: loadable(
      () => import('../../components/partner/detail/PartnerCompanyDetail.tsx'),
    ),
    exact: false,
    requiredRoles: partnerDefaultRequiredRoles,
    requiredFeatures: partnerDefaultRequiredFeatures,
    routes: [
      {
        name: 'partner.company.details.data',
        path: `${PATH}/company/details/:id/data`,
        component: loadable(
          () =>
            import('../../components/partner/detail/PartnerCompanyData.tsx'),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Details'),
        label: ({ t }) => t('Details'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: partnerDefaultRequiredFeatures,
      },
      {
        name: 'partner.company.details.charges',
        path: `${PATH}/company/details/:id/charges`,
        component: loadable(
          () =>
            import(
              '../../components/partner/detail/PartnerCompanyDataChargeInfiniteList.tsx'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Charges'),
        label: ({ t }) => t('Charges'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: partnerDefaultRequiredFeatures,
      },
      {
        name: 'partner.company.details.webhookLogs',
        path: `${PATH}/company/details/:id/webhookLogs`,
        component: loadable(
          () =>
            import(
              '../../components/partner/detail/PartnerCompanyDataWebhookLogInfiniteList.tsx'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Webhook logs'),
        label: ({ t }) => t('Webhook logs'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: partnerDefaultRequiredFeatures,
      },
      {
        name: 'partner.company.details.integrations',
        path: `${PATH}/company/details/:id/integrations`,
        component: loadable(
          () =>
            import(
              '../../components/partner/detail/PartnerCompanyDataIntegrations.tsx'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Api/Plugins'),
        label: ({ t }) => t('Api/Plugins'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.PARTNER] }],
      },
      {
        name: 'partner.company.details.settings',
        path: `${PATH}/company/details/:id/settings`,
        component: loadable(
          () =>
            import(
              '../../components/partner/detail/PartnerCompanyDataSettings.tsx'
            ),
        ),
        exact: true,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        requiredRoles: partnerDefaultRequiredRoles,
        requiredFeatures: [MODULES.SPLIT_PARTNER, MODULES.PARTNER_WALLET],
      },
    ],
  },
  {
    name: 'partner.account.register.new',
    path: `${PATH}/account-register/:companyId/new`,
    component: loadable(
      () =>
        import(
          '../../components/accountRegister/create/AccountRegisterProviderNew.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: partnerDefaultRequiredRoles,
    requiredFeatures: partnerDefaultRequiredFeatures,
  },
  {
    name: 'partner.account.register.edit',
    path: `${PATH}/account-register/:companyId/edit/:id`,
    component: loadable(
      () =>
        import(
          '../../components/accountRegister/create/new/AccountRegisterFormNew.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: partnerDefaultRequiredRoles,
    requiredFeatures: partnerDefaultRequiredFeatures,
  },
  {
    name: 'partner.create.batch',
    path: `${PATH}/create-batch`,
    component: loadable(
      () => import('../../components/partner/import/PartnerImport.tsx'),
    ),
    exact: true,
    requiredRoles: partnerDefaultRequiredRoles,
    requiredFeatures: partnerDefaultRequiredFeatures,
  },
];
