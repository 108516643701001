import loadable from '@loadable/component';

// Template
import RequiredTemplate from '../../../components/user/required/requiredTemplate.tsx';
import { APP_PATH } from '../openpix/appPath.tsx';

const PATH = `${APP_PATH}/required`;

const requiredRoutes = {
  name: 'required',
  path: PATH,
  component: RequiredTemplate,
  exact: false,
  requiredFeatures: [],
  routes: [
    {
      name: 'required.should-confirm-withdraw-rules',
      path: `${PATH}/should-confirm-withdraw-rules`,
      component: loadable(
        () =>
          import(
            '../../../components/user/required/ShouldConfirmWithdrawRules.tsx'
          ),
      ),
      requiredFeatures: [],
      exact: true,
    },
    {
      name: 'required.should-confirm-plan',
      path: `${PATH}/should-confirm-plan`,
      component: loadable(
        () => import('../../../components/user/required/ShouldConfirmPlan.tsx'),
      ),
      requiredFeatures: [],
      exact: true,
    },
  ],
};

export default requiredRoutes;
