import { Typography } from '@mui/material';
import { graphql, useFragment } from 'react-relay';

import { Avatar, BoxFlex } from '@woovi/ui';

import type { UserMenuProfile_user$key } from './__generated__/UserMenuProfile_user.graphql.ts';

import type { ReactNode } from 'react';

type UserMenuProfileProps = {
  user: UserMenuProfile_user$key;
};

export const UserMenuProfile = (props: UserMenuProfileProps): ReactNode => {
  const user = useFragment<UserMenuProfile_user$key>(
    graphql`
      fragment UserMenuProfile_user on User {
        profileImage {
          url
        }
        userMaster {
          name
        }
      }
    `,
    props.user,
  );

  const profileImage = user?.profileImage;
  const profileImageUrl = profileImage ? profileImage.url : null;

  return (
    <BoxFlex sx={{ alignItems: 'center', gap: 1, minWidth: '240px' }}>
      <Avatar
        name={user?.userMaster?.name || ''}
        size={28}
        imageUrl={profileImageUrl}
      />
      <Typography fontWeight={600}>{user?.userMaster?.name}</Typography>
    </BoxFlex>
  );
};
