import { Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import { withRelayBoundary, type FetchKeyProp } from '@woovi/relay';
import { PROVIDER_CODE, PROVIDER_FEATURE_ENUM } from '@woovi/shared';
import { convertToMoney } from '@woovi/utils';

import type { UserMenuAccountItemBalance_account$key } from './__generated__/UserMenuAccountItemBalance_account.graphql.ts';
import type { UserMenuAccountItemBalanceQuery } from './__generated__/UserMenuAccountItemBalanceQuery.graphql.ts';
import { useProviderFeatures } from '../../companyBankAccount/provider/useProviderFeatures.tsx';

import type { ReactNode } from 'react';

type UserMenuAccountItemBalanceProps = {
  accountId: string;
} & FetchKeyProp;

const UserMenuAccountItemBalance = ({
  accountId,
  fetchKey,
}: UserMenuAccountItemBalanceProps): ReactNode => {
  const { t } = useTranslation();

  const response = useLazyLoadQuery<UserMenuAccountItemBalanceQuery>(
    graphql`
      query UserMenuAccountItemBalanceQuery($id: ID!) {
        account: node(id: $id) {
          ... on CompanyBankAccount {
            ...UserMenuAccountItemBalance_account
          }
        }
      }
    `,
    {
      id: accountId,
    },
    {
      fetchKey,
    },
  );

  const account = useFragment<UserMenuAccountItemBalance_account$key>(
    graphql`
      fragment UserMenuAccountItemBalance_account on CompanyBankAccount {
        balance
        code
        ...useProviderFeatures_account
      }
    `,
    response.account,
  );

  const { hasProviderFeature, providerFeatures } = useProviderFeatures(account);

  const getSimulatedData = (): ReactNode => {
    if (account?.code !== PROVIDER_CODE.TEST) {
      return null;
    }

    return (
      <Typography variant='body2' color='GrayText'>
        {t('Simulated data')}
      </Typography>
    );
  };

  if (!hasProviderFeature(PROVIDER_FEATURE_ENUM.BALANCE)) {
    return null;
  }

  if (account?.balance === null) {
    return (
      <>
        {getSimulatedData()}
        <Typography variant='body2' color={'error'}>
          {t('Error on load balance')}
        </Typography>
      </>
    );
  }

  return (
    <>
      {getSimulatedData()}
      <Typography variant='body2' color='GrayText'>
        {t('Balance')}: {convertToMoney(account?.balance || 0)}
      </Typography>
    </>
  );
};

export default withRelayBoundary(UserMenuAccountItemBalance, {
  loadingView: (
    <Skeleton
      data-testid='loading'
      animation={'wave'}
      width={100}
      height={18}
    />
  ),
});
