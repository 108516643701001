import { cellTypeBoolean } from './cellTypeBoolean.tsx';
import { cellTypeCurrency } from './cellTypeCurrency.ts';
import { cellTypeDate } from './cellTypeDate.ts';
import { cellTypeDatetime } from './cellTypeDatetime.ts';
import { cellTypeDatetimeSeconds } from './cellTypeDatetimeSeconds.ts';
import { cellTypePhone } from './cellTypePhone.tsx';
import { cellTypeTag } from './cellTypeTag.tsx';
import { cellTypeTaxID } from './cellTypeTaxID.tsx';
import { cellTypeTime } from './cellTypeTime.ts';

export const columnTypes = {
  boolean: cellTypeBoolean,
  currency: cellTypeCurrency,
  dateAny: cellTypeDate,
  datetime: cellTypeDatetime,
  datetimeSeconds: cellTypeDatetimeSeconds,
  phone: cellTypePhone,
  tag: cellTypeTag(),
  taxID: cellTypeTaxID,
  time: cellTypeTime,
};
