import type { CardProcessingProvider } from './CardProcessingProvider.tsx';

let providers: CardProcessingProvider[] = [];

export const getCardProcessingProviders = (): CardProcessingProvider[] =>
  providers;

export const registerCardProcessingProvider = (
  provider: CardProcessingProvider,
) => {
  const existingProvider = providers.find((p) => p.name === provider.name);

  if (existingProvider) {
    return;
  }

  providers = [...providers, provider];
};
