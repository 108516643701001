import type { CacheConfig, UploadableMap, Variables } from 'react-relay';
import type { RequestParameters } from 'relay-runtime';
import { QueryResponseCache } from 'relay-runtime';
import type { Sink } from 'relay-runtime/lib/network/RelayObservable';

import { forceFetch, isMutation } from '@woovi/relay';

import { networkFetchSink } from './networkFetchSink.ts';

const oneMinute = 60 * 1000;

export const relayResponseCache = new QueryResponseCache({
  size: 250,
  ttl: oneMinute,
});

export const networkCacheSink = async (
  request: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables: UploadableMap,
  sink: Sink<unknown>,
): Promise<void> => {
  const queryID = request.text;

  if (isMutation(request)) {
    relayResponseCache.clear();
  }

  const fromCache = relayResponseCache.get(queryID, variables);

  if (fromCache !== null && forceFetch(cacheConfig) === false) {
    sink.next(fromCache);
    sink.complete();

    // return fromCache;
    return;
  }

  try {
    const data = await networkFetchSink(
      request,
      variables,
      cacheConfig,
      uploadables,
    );

    relayResponseCache.set(queryID, variables, data);

    sink.next(data);
    sink.complete();

    return;
  } catch (err) {
    if (err?.cause?.code === 401) {
      sink.complete();
    }

    sink.error(err);

    return;
  }
};
