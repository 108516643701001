import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { MODULES } from '@woovi/roles';

import { routeToMain } from '../../../../router/utils/index.tsx';
import type { menuItems_query } from '../../__generated__/menuItems_query.graphql.ts';

type Rewards = {
  query: menuItems_query;
};
export const rewards = ({ t, hasFeature, query }: Rewards) => {
  const hasCashback = hasFeature([MODULES.CASHBACK]);

  const hasRewardsActive =
    query.me.company.preferences?.cashback?.active ||
    query.me.company.preferences?.cashbackExclusive?.active;

  const hasCashbackData = query.cashbacks.edges.length > 0;

  const goToTutorial = !hasRewardsActive && !hasCashbackData;

  const label = () => {
    return t('Cashback');
  };

  const rewardsRoute = {
    label: label(),
    icon: <AddShoppingCartIcon />,
  };

  return [
    {
      ...rewardsRoute,
      link: goToTutorial
        ? routeToMain('rewards.tutorial')
        : routeToMain('rewards'),
    },
  ];
};
