import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ActionButton } from '@woovi/ui';

import { routeToMain } from '../../../router/utils/index.tsx';

type UserMenuActionsProps = {
  onClose: () => void;
};

export const UserMenuActions = ({ onClose }: UserMenuActionsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleAccountEdit = () => {
    history.push(routeToMain('me.account.edit'));
    onClose();
  };

  const handleLogout = () => history.push(routeToMain('me.logout'));

  return (
    <Stack gap={1}>
      <ActionButton
        variant='outlined'
        color='secondary'
        onClick={handleAccountEdit}
      >
        {t('My Account')}
      </ActionButton>
      <ActionButton color='error' variant='outlined' onClick={handleLogout}>
        {t('Log Out')}
      </ActionButton>
    </Stack>
  );
};
