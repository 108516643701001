import { useContext } from 'react';

import { SidebarContext } from './SidebarContext.tsx';

const useSidebar = () => {
  const [isSidebarOpen, setIsOpen] = useContext(SidebarContext);

  const sidebarOpen = () => {
    setIsOpen(true);
  };

  const sidebarClose = () => {
    setIsOpen(false);
  };

  const sidebarToggle = () => {
    setIsOpen(!isSidebarOpen);
  };

  return {
    isSidebarOpen,
    sidebarOpen,
    sidebarClose,
    sidebarToggle,
  };
};

export default useSidebar;
