import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'react-relay';

import { Sidebar as _Sidebar } from '@woovi/ui';

import type { Sidebar_query$key } from './__generated__/Sidebar_query.graphql.ts';
import items from './menuItems.tsx';
import SidebarHeader from './SidebarHeader.tsx';
import { useUserFlags } from '../../core/hooks/useUserFlags.tsx';

import type { ReactNode } from 'react';

export type Props = {
  query: Sidebar_query$key;
};
const Sidebar = (props: Props): ReactNode => {
  const query = useFragment<Sidebar_query$key>(
    graphql`
      fragment Sidebar_query on Query {
        ...menuItems_query
        me {
          ...useUserFlagsFragment @arguments(key: "view_sidebar_pix_machine")
          ...permissionsUserFragment @relay(mask: false)
          ...SidebarHeader_me
          id
          name
          isAdmin
          allRoles
          hasDataExports
          company {
            projectType
            features
            nameFriendly
            projectType
          }
        }
        version
      }
    `,
    props.query,
  );

  const { t } = useTranslation();
  const { me } = query;

  const [getFlags] = useUserFlags(me);

  const sidebarItems = useMemo(() => {
    return items({ menuItemsFragmentRef: query, flags: getFlags(), t });
  }, [query, t, getFlags]);

  return <_Sidebar header={<SidebarHeader me={me} />} items={sidebarItems} />;
};

export default Sidebar;
