import { useMemo } from 'react';

import { CHARGE_STATUS_ENUM } from '@woovi/shared';

import cashSound from './cashSoundFile.mp3';
import type { ChargeUpdatedStatusSubscription$data } from '../../charge/subscription/__generated__/ChargeUpdatedStatusSubscription.graphql.ts';
import { useChargeUpdatedStatusSubscription } from '../../charge/subscription/useChargeUpdatedStatusSubscription.tsx';

export const CashSoundHook = () => {
  const audio = useMemo(() => new Audio(cashSound), []);

  const onNext = (data: ChargeUpdatedStatusSubscription$data) => {
    const charge = data?.ChargeUpdatedStatus?.charge;

    if (!charge) {
      return;
    }

    if (charge.status === CHARGE_STATUS_ENUM.COMPLETED) {
      audio.currentTime = 0;
      audio.play();
    }
  };

  useChargeUpdatedStatusSubscription(
    {
      input: {},
    },
    onNext,
  );
};
