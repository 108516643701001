import { useField } from 'formik';
import { IMaskMixin } from 'react-imask';
import type { ReactMaskProps } from 'react-imask/dist/mixin';

import type { TextFieldProps } from './TextField.tsx';
import TextField from './TextField.tsx';

import type { ReactNode } from 'react';

export type TextFieldMaskedProps = TextFieldProps & ReactMaskProps;

const TextFieldMasked = ({
  name,
  onBlur,
  onChange,
  helperText,
  ...props
}): ReactNode => {
  const [field, meta, helpers] = useField(name);

  const getErrorProps = () => {
    if (!meta?.touched) {
      return {};
    }

    if (typeof meta?.error === 'string') {
      return {
        error: !!meta?.error,
        helperText: meta?.error,
      };
    }

    return {};
  };

  const errorProps = getErrorProps();

  const handleChange = (event: Event) => {
    const { value } = event.target;

    helpers.setValue(value);
    onChange && onChange(event);
  };

  const handleBlur = (e) => {
    field.onBlur(e);
    onBlur && onBlur(e);
  };

  const dataTestIdInputProps = {
    'data-testid': `${name}`,
  };

  const textInputProps = props.inputProps
    ? { ...dataTestIdInputProps, ...props.inputProps }
    : dataTestIdInputProps;

  return (
    <TextField
      name={name}
      value={field.value}
      helperText={helperText}
      inputProps={textInputProps}
      onChange={handleChange}
      onBlur={handleBlur}
      {...errorProps}
      {...props}
    />
  );
};

const getMaskedInput = () => {
  if (process.env.IS_RSPACK === 'true') {
    return TextFieldMasked;
  }

  return IMaskMixin(TextFieldMasked);
};

const TextFieldMaskedMixing = getMaskedInput();

export default TextFieldMaskedMixing;
