import { Box } from '@mui/material';
import type { CardProps as MUICardProps } from '@mui/material/Card';
import _CardMUI from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import {
  space,
  flexbox,
  layout,
  border,
  display,
  position,
} from 'styled-system';
import type {
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  BorderProps,
  PositionProps,
} from 'styled-system';

import BoxFlex from '../mui/BoxFlex.tsx';

type ExtendedCardProps = MUICardProps &
  FlexboxProps &
  LayoutProps &
  BorderProps &
  SpaceProps &
  PositionProps;

const CardMUI = styled(_CardMUI)<ExtendedCardProps>`
  box-sizing: border-box;
  ${flexbox}
  ${layout}
  ${border}
  ${space}
  ${display}
  ${position}
  
  @media print {
    box-shadow: none;
  }
`;

export type CardProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
} & ExtendedCardProps;

const Card = forwardRef(
  ({ children, disabled, sx, ...props }: CardProps, ref) => {
    return (
      <CardMUI
        variant='outlined'
        sx={{ borderRadius: '8px', ...sx }}
        {...props}
        ref={ref}
      >
        <BoxFlex
          sx={{ position: 'relative', flexDirection: 'column', height: '100%' }}
        >
          {disabled && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: 'rgba(255, 255, 255, 0.5)',
                zIndex: 2,
                borderRadius: 8,
              }}
            />
          )}
          {children}
        </BoxFlex>
      </CardMUI>
    );
  },
);

export { CardContent, CardHeader, CardMedia, CardActions };

export default Card;
