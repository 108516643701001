/* eslint-disable react-hooks/rules-of-hooks */
// https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api

import { useEffect } from 'react';

import clarity from './clarity.ts';

type UseClarity = {
  clarityId: string;
  userId?: string;
  tags?: Record<string, unknown>;
};

export const useClarity = ({ clarityId, userId, tags }: UseClarity) => {
  if (process.env.APP_ENV !== 'production') {
    return;
  }

  if (!clarityId) {
    // eslint-disable-next-line no-console
    console.log(`Failed to load Clarity with CLARITY_ID: ${clarityId}`);

    return;
  }

  if (!clarity.hasStarted()) {
    clarity.init(clarityId);
  }

  useEffect(() => {
    if (!clarity.hasStarted()) {
      return;
    }

    if (userId) {
      clarity.identify(userId);
    }

    if (tags) {
      for (const [key, value] of Object.entries(tags)) {
        clarity.setTag(key, value);
      }
    }
  }, [clarity, userId]);
};
