import type { FunctionComponent, ReactNode } from 'react';
import { isValidElement, Suspense } from 'react';

import { Loading, ErrorViewBoundary } from '@woovi/ui';

import type { FetchKeyProp } from './FetchKeyProp.tsx';

type Config<T> = {
  loadingView?:
    | NonNullable<ReactNode>
    | ((props: T) => NonNullable<ReactNode>)
    | null;
};

export const withRelayBoundary = <T extends FetchKeyProp>(
  Component: FunctionComponent<T>,
  config: Config<T> = {},
): FunctionComponent<Omit<T, 'fetchKey'>> => {
  const { loadingView = <Loading fullScreen /> } = config;

  const QueryRendererWrapper = (props: T): ReactNode => {
    const renderLoadingView = (): ReactNode => {
      if (isValidElement(loadingView)) {
        return loadingView;
      }

      if (typeof loadingView === 'function') {
        return loadingView(props);
      }

      return loadingView;
    };

    return (
      <ErrorViewBoundary>
        {({ fetchKey }) => (
          <Suspense fallback={renderLoadingView()}>
            <Component {...props} fetchKey={fetchKey} />
          </Suspense>
        )}
      </ErrorViewBoundary>
    );
  };

  QueryRendererWrapper.displayName = `withRelayBoundary(${
    Component.displayName || Component.name
  })`;

  return QueryRendererWrapper;
};
