import type { ReactNode } from 'react';

import type { CardProps } from '../card/Card.tsx';
import Card from '../card/Card.tsx';
import CardActions from '../card/CardActions.tsx';
import type { GridProps } from './Grid.tsx';
import Grid from './Grid.tsx';

export type CardGridProps = {
  children: ReactNode;
  cardProps?: Partial<CardProps>;
  gridProps?: GridProps;
  actions?: ReactNode;
  templateColumns?: string;
};
const CardGrid = ({
  children,
  cardProps = {},
  gridProps = {},
  actions,
  templateColumns = 'repeat(auto-fit, minmax(150px, 1fr))',
}: CardGridProps): ReactNode => {
  return (
    <Card mb='10px' {...cardProps}>
      <Grid
        templateColumns={templateColumns}
        gap='15px'
        padding='16px'
        {...gridProps}
      >
        {children}
      </Grid>
      {!!actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
};

export default CardGrid;
