import styled from 'styled-components';

import {
  CHARGE_STATUS_ENUM,
  SUBSCRIPTION_INSTALLMENT_STATUS_ENUM,
} from '@woovi/shared';

import { default as _Tag } from '../../tag/Tag.tsx';

export const TAG_STATUS_COLOR = {
  [CHARGE_STATUS_ENUM.ACTIVE]: '#FF9800',
  [CHARGE_STATUS_ENUM.ACTIVE_WAITING_PAYMENT_METHOD]: '#FF9800',
  [CHARGE_STATUS_ENUM.EXPIRED]: '#ff7f50',
  [CHARGE_STATUS_ENUM.OVERDUE]: '#e65100',
  [CHARGE_STATUS_ENUM.COMPLETED]: '#4CAF50',
  [SUBSCRIPTION_INSTALLMENT_STATUS_ENUM.SCHEDULED]: 'rgb(0 149 255)', // status for when the installment of charge is scheduled
};
export const ChargeTagStatus = styled(_Tag)<{ status: string }>`
  background-color: ${(props) => TAG_STATUS_COLOR[props.status]} !important;
  color: white !important;
`;
