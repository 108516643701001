import loadable from '@loadable/component';

import type { RouteType } from '../utils/index.tsx';

export default (path: string): RouteType[] => [
  {
    name: 'legacyHandler',
    path: `${path}`,
    component: loadable(() => import('../components/LegacyRoutes.tsx')),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'notFound',
    path: `${path}`,
    component: loadable(() => import('../components/NotFound.tsx')),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
  },
];
