/**
 * @generated SignedSource<<4d82858325e5f784e0ae20381c02b072>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderButtonTheme_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlag_user">;
  readonly " $fragmentType": "HeaderButtonTheme_user";
};
export type HeaderButtonTheme_user$key = {
  readonly " $data"?: HeaderButtonTheme_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderButtonTheme_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderButtonTheme_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureFlag_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "36b8c668e46ae8cf66667b1dd5e82f52";

export default node;
