import { config } from '@woovi/shared';

import { getBaseUrl } from '../pagarMeApi.tsx';

type PagarmeTokenizeCreditCardArgs = {
  payload: {
    type: string;
    card: {
      number: string;
      holder_name: string;
      holder_document: string;
      exp_month: string;
      exp_year: string;
      cvv: string;
      brand?: string;
      label?: string;
    };
  };
};

type PagarmeTokenizeCreditCardResult = {
  id: string;
  type: string;
  created_at: string;
  expires_at: string;
  card: Card;
};

type Card = {
  first_six_digits: string;
  last_four_digits: string;
  holder_name: string;
  holder_document: string;
  exp_month: number;
  exp_year: number;
  brand: string;
};

export const pagarmeTokenizeCreditCard = async (
  args: PagarmeTokenizeCreditCardArgs,
): Promise<PagarmeTokenizeCreditCardResult> => {
  const { payload } = args;

  const appId = config.PAGAR_ME_PUBLIC_KEY || '';

  const queryParams = new URLSearchParams({
    appId,
  }).toString();

  const url = `${getBaseUrl()}/tokens?${queryParams}`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const response = await fetch(url, options);
  const data = await response.json();

  if (data) {
    data.response = response;
  }

  return data;
};
