import type { Location } from 'history';
import { matchPath } from 'react-router-dom';

import type { RouteType } from './types.ts';

const flattenRoutes = (routes: RouteType[]): RouteType[] =>
  routes.reduce((acc, cur) => {
    const subRoutesFlattened: RouteType[] = [];

    if (Array.isArray(cur.routes)) {
      subRoutesFlattened.push(...flattenRoutes(cur.routes));
    }

    return acc.concat([cur, ...subRoutesFlattened]);
  }, []);

export const getRouteFromPath = (routes: RouteType[]) => (path: string) => {
  const allRoutes = flattenRoutes(routes);

  const matchedRoutes = allRoutes
    .filter((route) => route.type !== 'REDIRECT')
    .filter((route) => !!matchPath(path, { path: route.path, exact: true }));

  return matchedRoutes?.[0] || {};
};

export const hasMatchChildren = (
  location: Location,
  childrenRoutes: RouteType[],
) => {
  const { pathname } = location;

  return (
    childrenRoutes.filter((route) =>
      matchPath(pathname, { path: route.path, exact: true }),
    ).length > 0
  );
};

export const findRouteTreePath =
  (routes: RouteType[]) => (leafRoute: RouteType) => {
    const traverse = (node: RouteType, cmp: RouteType): RouteType[] | null => {
      if (node.name === cmp.name) {
        return [];
      } else if (Array.isArray(node.routes)) {
        const { routes } = node;

        for (let i = 0; i < routes.length; i++) {
          const path = traverse(routes[i], cmp);

          if (path) {
            return [routes[i], ...path];
          }
        }
      }

      return null;
    };

    const rootRoutes = routes.find((route) => route.name === 'rootRoutes');

    return traverse(rootRoutes, leafRoute);
  };

type hasMatchedOpts = {
  path: string;
  exact?: boolean;
};

export const hasMatched = (
  location: Location,
  options: hasMatchedOpts,
): boolean => {
  const { path = '', exact = true } = options;
  const { pathname } = location;

  return matchPath(pathname, { path, exact }) !== null;
};
