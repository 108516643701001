import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES } from '@woovi/roles';

import type { RouteType } from '../../utils/index.tsx';

const PATH = '/home/card';

export const cardRoutes: RouteType[] = [
  {
    name: 'card',
    path: `${PATH}/transactions`,
    component: loadable(() => import('../../../components/card/CardTabs.tsx')),
    exact: false,
    labelHeader: ({ t }) => t('Cards'),
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.CREDIT_CARD],
    routes: [
      {
        name: 'card.transaction.list',
        component: loadable(
          () => import('../../../components/card/CardTransactionList.tsx'),
        ),
        path: `${PATH}/transactions/list`,
        exact: true,
        label: ({ t }) => t('Transactions'),
        labelHeader: ({ t }) => t('Transactions'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.CREDIT_CARD],
      },
    ],
  },
  {
    name: 'card.details',
    path: `${PATH}/transaction/:id`,
    labelHeader: ({ t }) => t('Payment'),
    label: ({ t }) => t('Payment'),
    component: loadable(
      () => import('../../../components/card/CardTransactionDetails.tsx'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.CREDIT_CARD],
    routes: [
      {
        name: 'card.transaction.details.data',
        path: `${PATH}/transaction/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () => import('../../../components/card/CardTransactionData.tsx'),
        ),
        exact: true,
        requiredFeatures: [MODULES.OPEN_PIX],
        requiredRoles: [ADMIN_ROLE],
      },
    ],
  },
];
