import type { Sidebar_query } from './__generated__/Sidebar_query.graphql.ts';
import type { MenuItem } from './menuItems.tsx';
import {
  getPermissionsFromRoutes,
  groupHasPermission,
  hasPermission,
} from '../../security/index.tsx';

export const filterItems = (items: MenuItem[], query: Sidebar_query) => {
  return items.reduce((acc, item) => {
    const {
      requiredRoles = [],
      requiredFeatures = [],
      requiredProjectsTypes = [],
    } = item;

    const shouldShowMenuItem = hasPermission(
      query.me,
      requiredFeatures,
      requiredRoles,
      requiredProjectsTypes,
    );

    if (item.link === null) {
      return acc;
    }

    if (item.link) {
      const permissions = getPermissionsFromRoutes([item.link]);
      const isDisplayed = (item.isDisplayed || []).every((fn) => fn(query.me));
      const shouldShow =
        groupHasPermission(query.me, permissions) &&
        hasPermission(query.me, requiredFeatures, requiredRoles) &&
        isDisplayed;

      if (shouldShow) {
        return [...acc, item];
      }

      return acc;
    }

    if (item.items) {
      const subitems = filterItems(item.items, query);

      if (subitems.length === 0) {
        return acc;
      }

      if (shouldShowMenuItem) {
        return [
          ...acc,
          {
            ...item,
            items: subitems,
          },
        ];
      }

      return acc;
    }

    if (shouldShowMenuItem) {
      return [...acc, item];
    }

    return acc;
  }, []);
};
