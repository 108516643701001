import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useMemo, forwardRef } from 'react';
import { useLocation, matchPath, NavLink } from 'react-router-dom';

import useSidebar from './useSidebar.tsx';

import type { ReactNode } from 'react';

type Props = {
  link: string;
  label: string;
  isMobile: boolean;
};

const Item = ({ link, label, isMobile }: Props): ReactNode => {
  const location = useLocation();
  const { sidebarClose } = useSidebar();

  // it needs to be exact: false, to routes with nested routes
  const hasMatched = !!matchPath(location.pathname, {
    path: link,
    exact: true,
  });

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <NavLink to={link} ref={ref} {...itemProps} />
      )),
    [link],
  );

  const onClick = () => {
    if (isMobile) {
      sidebarClose();
    }
  };

  return (
    <ListItem
      button
      dense
      component={renderLink}
      onClick={onClick}
      disableGutters
      selected={hasMatched}
    >
      <ListItemText primary={label} />
    </ListItem>
  );
};

export default Item;
