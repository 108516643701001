import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';

import type { useChatwoot_user$key } from './__generated__/useChatwoot_user.graphql.ts';
import { getChatWootLinkToConsole } from '../utils/getChatWootLinkToConsole.ts';

const userChatwootFragment = graphql`
  fragment useChatwoot_user on User @inline {
    _id
    id
    name
    email
    company {
      id
      nameFriendly
    }
    phoneNumbers
    userMaster {
      id
      chatWootAuth
    }
  }
`;

type UseChatwoot = {
  userRef: useChatwoot_user$key;
  crispId: string;
};

export const useChatwoot = ({ userRef }: UseChatwoot): void => {
  const user = readInlineData(userChatwootFragment, userRef);

  const getUserPhone = (): string | null | undefined => {
    if (user.phoneNumbers && user.phoneNumbers.length > 0) {
      return user.phoneNumbers[0];
    }

    return null;
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const phone = getUserPhone();

    const userHash = user.userMaster?.chatWootAuth;
    const id = user.userMaster?.id;

    const onReady = (): void => {
      window.$chatwoot.setUser(id, {
        email: user?.email,
        name: user?.name,
        phone_number: phone,
        company_name: user?.company?.nameFriendly,
        identifier_hash: userHash,
      });

      window.$chatwoot.setCustomAttributes({
        console: getChatWootLinkToConsole(user?.company?.id),
        company: user?.company?.nameFriendly,
      });
    };

    const onError = (error: unknown): void => {
      // eslint-disable-next-line
      console.log(error);
    };

    window.addEventListener('chatwoot:ready', onReady);

    window.addEventListener('chatwoot:error', onError);

    return () => {
      window.removeEventListener('chatwoot:ready', onReady);
      window.addEventListener('chatwoot:error', onError);
    };
  }, [user]);
};
