import 'moment/locale/pt-br';

import { StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StylesProvider } from '@mui/styles'; // v1.x
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import type { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';

import { withErrorBoundary } from '@woovi/hooks';
import {
  ThemeMuiTheme,
  ThemeProvider,
  Loading,
  SnackbarProvider as SnackbarProviderUI,
  DialogProvider,
} from '@woovi/ui';

import i18n from './i18n/i18n.tsx';

LicenseInfo.setLicenseKey(
  '1b821b7a1e5807e056cbb4491c3cc575Tz05NjQ3NyxFPTE3NTU3MDkxMjAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

export type ProvidersProps = {
  children: ReactNode;
};

const Providers = ({ children }: ProvidersProps): ReactNode => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={ThemeMuiTheme}>
        <StyledEngineProvider injectFirst>
          <Suspense fallback={<Loading fullScreen />}>
            <I18nextProvider i18n={i18n}>
              <SnackbarProviderUI>
                <SnackbarProvider>
                  <DialogProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale='pt-br'
                    >
                      {children}
                      <CssBaseline />
                    </LocalizationProvider>
                  </DialogProvider>
                </SnackbarProvider>
              </SnackbarProviderUI>
            </I18nextProvider>
          </Suspense>
        </StyledEngineProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default withErrorBoundary(Providers);
