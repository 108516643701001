import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ActionButton,
  SwipeableDialogActions,
  SwipeableDialogClose,
  SwipeableDialogContent,
} from '@woovi/ui';

import { routeToMain } from '../../router/utils/index.tsx';

import type { ReactNode } from 'react';

export const AccountActivateTutorialModalContent = (): ReactNode => {
  const { t } = useTranslation();

  return (
    <>
      <SwipeableDialogContent sx={{ '& .MuiDialogContent-root': { p: 0 } }}>
        <Stack py={2} px={3}>
          <Typography>
            {t(
              "Provide additional information to make the most of the platform's advanced features.",
            )}
          </Typography>
        </Stack>
      </SwipeableDialogContent>
      <SwipeableDialogActions>
        <SwipeableDialogClose>
          <ActionButton variant='text' color='secondary'>
            {t('Cancel')}
          </ActionButton>
        </SwipeableDialogClose>
        <ActionButton
          link={routeToMain('account.register.provider.new')}
          data-testid={'btn-activate-account'}
        >
          {t('Complete data')}
        </ActionButton>
      </SwipeableDialogActions>
    </>
  );
};
