import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';

import { useMutationCallbacks } from '@woovi/relay';
import { ActionButton, SwipeableModal } from '@woovi/ui';

import type { UserMenuConfirmDefaultAccountModal_account$key } from './__generated__/UserMenuConfirmDefaultAccountModal_account.graphql.ts';
import type { CompanyBankAccountSetDefaultMutation } from '../../companyBankAccount/__generated__/CompanyBankAccountSetDefaultMutation.graphql.ts';
import { CompanyBankAccountSetDefault } from '../../companyBankAccount/CompanyBankAccountSetDefault.tsx';

import type { ReactNode } from 'react';

type UserMenuConfirmDefaultAccountModalProps = {
  open: boolean;
  onClose: () => void;
  account: UserMenuConfirmDefaultAccountModal_account$key;
};

export const UserMenuConfirmDefaultAccountModal = ({
  open,
  onClose,
  ...props
}: UserMenuConfirmDefaultAccountModalProps): ReactNode => {
  const { t } = useTranslation();

  const account = useFragment(
    graphql`
      fragment UserMenuConfirmDefaultAccountModal_account on CompanyBankAccount {
        id
      }
    `,
    props.account,
  );

  const [companyBankAccountSetDefault, isPending] =
    useMutationCallbacks<CompanyBankAccountSetDefaultMutation>({
      name: 'CompanyBankAccountSetDefault',
      mutation: CompanyBankAccountSetDefault,
      afterCompleted: () => onClose(),
    });

  const handleConfirm = () => {
    const config = {
      variables: {
        input: {
          id: account.id,
        },
      },
    };

    companyBankAccountSetDefault(config);
  };

  const renderActions = (): ReactNode => {
    return (
      <>
        <ActionButton
          disabled={isPending}
          variant='text'
          color='secondary'
          onClick={onClose}
        >
          {t('Cancel')}
        </ActionButton>
        <ActionButton
          onClick={handleConfirm}
          loading={isPending}
          disabled={isPending}
        >
          {t('Confirm')}
        </ActionButton>
      </>
    );
  };

  return (
    <SwipeableModal
      title={t('Change default account')}
      open={open}
      onClose={onClose}
      actions={renderActions()}
    >
      <Typography>
        {t(
          'When you change the default account, all charges will be generated for the selected account.',
        )}
      </Typography>
    </SwipeableModal>
  );
};
