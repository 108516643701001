import { Typography, type SxProps } from '@mui/material';
import { formatToCEP, isCEP } from 'brazilian-values';
import dot from 'dot-object';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'relay-runtime';

import { FieldRow } from '@woovi/ui';
import { onlyDigits } from '@woovi/utils';

import type { AddressQuery } from './__generated__/AddressQuery.graphql.ts';
import TextField from '../textInput/index.tsx';
import TextFieldMasked from '../textInput/TextFieldMasked.tsx';

export type AddressProps = {
  prefix?: string;
  isConfirmed?: boolean;
  alwaysEnabled?: boolean;
  disabled?: boolean;
  showHeader?: boolean;
  sx?: SxProps;
};

type DisabledFields = {
  street: boolean;
  neighborhood: boolean;
  city: boolean;
  state: boolean;
  number: boolean;
  complement: boolean;
};

const Address = ({
  prefix,
  isConfirmed,
  showHeader = true,
  alwaysEnabled = false,
  disabled = false,
  sx,
}: AddressProps): ReactNode => {
  const { t } = useTranslation();
  const environment = useRelayEnvironment();
  const [isLoading, setIsLoading] = useState(false);
  const { setTouched, setValues, values } = useFormikContext();
  const [lastZipcode, setLastZipcode] = useState<string>();

  const getFieldName = (name: string): string => {
    if (!prefix) {
      return name;
    }

    return `${prefix}.${name}`;
  };

  const getFieldValue = (name: string): string => {
    return dot.pick(getFieldName(name), values);
  };

  const getDisabledFields = () => {
    const zipcode = getFieldValue('zipcode');

    return {
      street: !zipcode || !!getFieldValue('street'),
      neighborhood: !zipcode || !!getFieldValue('neighborhood'),
      city: !zipcode || !!getFieldValue('city'),
      state: !zipcode || !!getFieldValue('state'),
      number: !zipcode,
      complement: !zipcode,
    };
  };

  const [disabledFields, setDisabledFields] =
    useState<DisabledFields>(getDisabledFields);

  const isFieldDisabled = (name: keyof DisabledFields): boolean => {
    return disabled || (!alwaysEnabled && disabledFields[name]);
  };

  const handleZipcodeChange = async (e): Promise<void> => {
    const zipcode = onlyDigits(e?.target?.value);

    if (!zipcode || !isCEP(zipcode) || zipcode === lastZipcode) {
      return;
    }

    setIsLoading(true);

    const query = await fetchQuery<AddressQuery>(
      environment,
      graphql`
        query AddressQuery($zipcode: String!) {
          address(zipcode: $zipcode) {
            street
            neighborhood
            city
            state
          }
        }
      `,
      {
        zipcode,
      },
    ).toPromise();

    if (!query?.address) {
      setIsLoading(false);

      return;
    }

    const { address } = query;

    setIsLoading(false);

    setValues(
      dot.object({
        ...values,
        [getFieldName('zipcode')]: formatToCEP(zipcode),
        [getFieldName('street')]: address.street,
        [getFieldName('neighborhood')]: address.neighborhood,
        [getFieldName('city')]: address.city,
        [getFieldName('state')]: address.state,
        [getFieldName('number')]: '',
        [getFieldName('complement')]: '',
      }),
    );

    setTouched(
      dot.object({
        [getFieldName('zipcode')]: true,
        [getFieldName('street')]: true,
        [getFieldName('neighborhood')]: true,
        [getFieldName('city')]: true,
        [getFieldName('state')]: true,
        [getFieldName('number')]: true,
        [getFieldName('complement')]: true,
      }),
    );

    setDisabledFields(getDisabledFields());

    setLastZipcode(zipcode);
  };

  useEffect(() => {
    setDisabledFields(getDisabledFields());
  }, [
    getFieldValue('zipcode'),
    getFieldValue('street'),
    getFieldValue('neighborhood'),
    getFieldValue('state'),
  ]);

  return (
    <>
      {showHeader && <Typography>{t('Address')}</Typography>}
      <TextFieldMasked
        name={getFieldName('zipcode')}
        mask='00000-000'
        label={t('Zipcode')}
        onChange={handleZipcodeChange}
        disabled={isLoading || isConfirmed || disabled}
        fullWidth
        sx={sx}
      />
      <FieldRow>
        <TextField
          name={getFieldName('street')}
          label={t('Street')}
          fullWidth
          disabled={isLoading || isConfirmed || isFieldDisabled('street')}
          sx={sx}
        />
        <TextField
          name={getFieldName('number')}
          label={t('Number')}
          fullWidth
          disabled={isLoading || isConfirmed || isFieldDisabled('number')}
          sx={sx}
        />
      </FieldRow>
      <FieldRow>
        <TextField
          name={getFieldName('neighborhood')}
          label={t('Neighborhood')}
          fullWidth
          disabled={isLoading || isConfirmed || isFieldDisabled('neighborhood')}
          sx={sx}
        />
        <TextField
          name={getFieldName('complement')}
          label={t('Complement')}
          fullWidth
          disabled={isLoading || isConfirmed || isFieldDisabled('number')}
          sx={sx}
        />
      </FieldRow>
      <FieldRow>
        <TextField
          name={getFieldName('city')}
          label={t('City')}
          fullWidth
          disabled={isLoading || isConfirmed || isFieldDisabled('city')}
          sx={sx}
        />
        <TextField
          name={getFieldName('state')}
          label={t('State')}
          fullWidth
          disabled={isLoading || isConfirmed || isFieldDisabled('state')}
          sx={sx}
        />
      </FieldRow>
    </>
  );
};

export default Address;
