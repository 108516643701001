import CardMembershipIcon from '@mui/icons-material/CardMembership';
import type { TFunction } from 'i18next';
import { graphql, readInlineData } from 'relay-runtime';

import type { subscriptions_company$key } from './__generated__/subscriptions_company.graphql.ts';
import { routeToMain } from '../../../router/utils/index.tsx';

const subscriptionsFragment = graphql`
  fragment subscriptions_company on Company @inline {
    subscriptions(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

type SubscriptionsArgs = {
  t: TFunction;
  company: subscriptions_company$key;
};

export const subscriptions = ({ t, ...args }: SubscriptionsArgs) => {
  const company = readInlineData<subscriptions_company$key>(
    subscriptionsFragment,
    args.company,
  );

  const getLink = () => {
    if (!company.subscriptions?.edges.length) {
      return routeToMain('subscription.tab.tutorial');
    }

    return routeToMain('subscription.list');
  };

  return [
    {
      label: t('Subscriptions/Automatic Pix'),
      icon: <CardMembershipIcon />,
      link: getLink(),
    },
  ];
};
