import { Typography } from '@mui/material';
import { getGridDefaultColumnTypes } from '@mui/x-data-grid-pro';
import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardColumn } from './CardColumn.tsx';
import { columnTypes } from '../datagrid/columnTypes.ts';
import { wrapColumnTypes } from '../datagrid/wrapColumnTypes.ts';
import BoxFlex from '../mui/BoxFlex.tsx';

// @todo: Fix this `any` types.
type CardListVirtualizedProps = {
  columns: any[];
  data: any[];
  onScrollToBottom: VoidFunction;
  onCardClick?: (card: any, event: React.MouseEvent<HTMLDivElement>) => void;
  noItemsLabel?: string;
  cardListDefaultHeight?: string;
};

// A function to ensure compatibility between card structure and DataGrid
// columns.
// @todo: Improve this function with another methods and better way to create
//        cell compatibily.
export const compatWithDataGrid = ({
  value,
  row,
}: Partial<GridRenderCellParams>) => ({
  value,
  row,
});

export const NATIVE_COLUMN_TYPES = getGridDefaultColumnTypes();

export const hasCustomColumnType = (column) =>
  column.type && !Object.keys(NATIVE_COLUMN_TYPES).includes(column.type);

export const formatValue = (value, column) => {
  if (hasCustomColumnType(column)) {
    if (!(column.type in columnTypes)) {
      // eslint-disable-next-line
      console.log('invalid column type: ', column.type);

      return value;
    }

    const hasValueFormatter =
      typeof columnTypes[column.type].valueFormatter === 'function';

    if (hasValueFormatter) {
      return columnTypes[column.type].valueFormatter({
        value,
        field: column.field,
      });
    }

    return value;
  }

  return typeof column.valueFormatter === 'function'
    ? column.valueFormatter({ value, field: column.field })
    : value;
};

export const getColumnValue = (row, column) => {
  const value =
    typeof column.valueGetter === 'function'
      ? column.valueGetter({ row })
      : row[column.field];

  const valueFormatted = formatValue(value, column);

  if (column.renderCell) {
    return column.renderCell(
      compatWithDataGrid({ value: valueFormatted, row }),
    );
  }

  // @todo: I don't know if it's the best way to do it.
  if (hasCustomColumnType(column)) {
    if (!(column.type in columnTypes)) {
      // eslint-disable-next-line
      console.log('invalid column type: ', column.type);

      return valueFormatted;
    }

    if (!columnTypes[column.type].renderCell) {
      return valueFormatted;
    }

    return columnTypes[column.type].renderCell(
      compatWithDataGrid({ value: valueFormatted, row }),
    );
  }

  return valueFormatted;
};

// is necessary to disable virtualization of card to render all items from card list
const isRunningOnJest = process.env.JEST_WORKER_ID !== undefined;

const CardListVirtualized = ({
  columns: _columns,
  data,
  onScrollToBottom,
  onCardClick,
  noItemsLabel,
  cardListDefaultHeight = 'calc(100vh - 156px)',
}: CardListVirtualizedProps) => {
  const parentRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    estimateSize: () => 80,
    overscan: 10,
    getScrollElement: () => parentRef.current,
  });

  const { t } = useTranslation();

  const defaultHeight = cardListDefaultHeight;
  const [height, setHeight] = useState<string | number>(defaultHeight);

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= data.length - 1) {
      onScrollToBottom();
    }
  }, [data.length, rowVirtualizer.getVirtualItems()]);

  useLayoutEffect(() => {
    setHeight(
      rowVirtualizer.getVirtualItems().length < 10
        ? rowVirtualizer.getVirtualItems().reduce((acc, el) => acc + el.size, 0)
        : defaultHeight,
    );
  }, [data.length, rowVirtualizer.getVirtualItems()]);

  const handleCardClick = (row, e) => {
    if (!onCardClick) {
      return;
    }

    return onCardClick({ row }, e);
  };

  if (data.length === 0) {
    if (noItemsLabel) {
      return (
        <BoxFlex
          sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          <Typography variant='h6'>{noItemsLabel}</Typography>
        </BoxFlex>
      );
    }

    return (
      <BoxFlex sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant='h6'>{t('No items')}</Typography>
      </BoxFlex>
    );
  }

  const columns = wrapColumnTypes(_columns);

  const getRenderItems = () => {
    if (isRunningOnJest) {
      // map to { index: number, start: number } to be compatible with useVirtualizer object
      return Array.from({ length: data.length }, (_, i) => ({
        index: i,
        start: 0,
      }));
    }

    return rowVirtualizer.getVirtualItems();
  };

  return (
    <div
      data-testid={'use-virtualizer'}
      ref={parentRef}
      className='List'
      style={{
        height,
        width: `100%`,
        overflow: 'auto',
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {getRenderItems().map((virtualItem) => {
          const row = data[virtualItem.index];

          return (
            <div
              key={virtualItem.index}
              data-index={virtualItem.index}
              ref={rowVirtualizer.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
                marginBottom: '16px',
              }}
            >
              <CardColumn
                columns={columns}
                row={row}
                onCardClick={onCardClick}
                handleCardClick={handleCardClick}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardListVirtualized;
