import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, es, ptBR } from '@woovi/i18n';

import { interpolationFormat } from './i18nInterpolation.tsx';

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translation: en,
    },
    'pt-BR': {
      translation: ptBR,
    },
    es: {
      translation: es,
    },
  },
  fallbackLng: 'pt-BR',
  debug: false,
  nsSeparator: false,
  keySeparator: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: interpolationFormat,
  },

  react: {
    useSuspense: true,
  },
});

export const tDate = (date: string | number | Date, fmt = 'dd/MM/yyyy') =>
  i18n.t('global_date_formatter', { date: { date: new Date(date), fmt } });

export const tTime = (date: string | number | Date, fmt = 'HH:mm:ss') =>
  i18n.t('global_time_formatter', { date: { date: new Date(date), fmt } });
export const tDateTime = (
  date: string | number | Date,
  fmt = 'dd/MM/yyyy HH:mm:ss',
) =>
  i18n.t('global_datetime_formatter', { date: { date: new Date(date), fmt } });

export default i18n;
