import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

import type { RouteType } from '../../utils/index.tsx';

export const accountRegisterRoutes: RouteType[] = [
  {
    name: 'account.register.new',
    path: '/home/account-register/new',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/new/AccountRegisterNewNew.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'account.register.edit',
    path: '/home/account-register/edit/:id',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/new/AccountRegisterFormNew.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'account.register.dock.new',
    path: '/home/account-register/dock/new',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/AccountRegisterDockNew.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
  },
  {
    name: 'account.register.dock.edit',
    path: '/home/account-register/dock/edit/:id',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/AccountRegisterDockForm.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
  },
  {
    name: 'account.register.fiducia.new',
    path: '/home/account-register/fiducia/new',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/AccountRegisterFiducia.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'account.register.woovi.new',
    path: '/home/account-register/woovi/new',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/AccountRegisterWoovi.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
  },
  {
    name: 'account.register.woovi.edit',
    path: '/home/account-register/woovi/edit/:id',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/AccountRegisterWooviForm.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'account.register.provider.new',
    path: '/home/account-register/provider/new',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/AccountRegisterProviderNew.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'account.register.fiducia.edit',
    path: '/home/account-register/fiducia/edit/:id',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/create/AccountRegisterFiduciaForm.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Account Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'account.register.representative.edit',
    path: '/home/account-register/:accountId/representative/:taxID/edit',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/representative/create/RepresentativeForm.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Representative Register'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'account.register.fiducia.representative.edit',
    path: '/home/account-register/fiducia/:accountId/representative/:taxID/edit',
    component: loadable(
      () =>
        import(
          '../../../components/accountRegister/representative/create/RepresentativeFormFiducia.tsx'
        ),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Register Representative'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
];
