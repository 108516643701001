import { Box, Radio, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import type { ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import { useSwipeableModal } from '@woovi/hooks';
import {
  COMPANY_BANK_ACCOUNT_STATUS_ENUM,
  getProviderName,
} from '@woovi/shared';
import { ActionButton } from '@woovi/ui';

import type { UserMenuAccountItem_account$key } from './__generated__/UserMenuAccountItem_account.graphql.ts';
import type { UserMenuAccountItem_user$key } from './__generated__/UserMenuAccountItem_user.graphql.ts';
import UserMenuAccountItemBalance from './UserMenuAccountItemBalance.tsx';
import UserMenuCompanyBankAccountStatus from './UserMenuCompanyBankAccountStatus.tsx';
import { UserMenuConfirmDefaultAccountModal } from './UserMenuConfirmDefaultAccountModal.tsx';
import FeatureTemp from '../../common/v2/featureFlag/FeatureTemp.tsx';

type UserMenuAccountItemProps = {
  selectedValue: string;
  account: UserMenuAccountItem_account$key;
  user: UserMenuAccountItem_user$key;
};

const UserMenuAccountItem = ({
  selectedValue,
  ...props
}: UserMenuAccountItemProps): ReactNode => {
  const { open, handleClose, handleOpen } = useSwipeableModal();

  const account = useFragment<UserMenuAccountItem_account$key>(
    graphql`
      fragment UserMenuAccountItem_account on CompanyBankAccount {
        id
        isDefault
        status
        name
        type
        code
        ...UserMenuConfirmDefaultAccountModal_account
        ...UserMenuCompanyBankAccountStatus_account
      }
    `,
    props.account,
  );

  const user = useFragment<UserMenuAccountItem_user$key>(
    graphql`
      fragment UserMenuAccountItem_user on User {
        ...FeatureTemp_user
      }
    `,
    props.user,
  );

  const accountName = account.isDefault
    ? `${account.name} | ${t('Default account')}`
    : account.name;

  return (
    <>
      <ActionButton
        variant='text'
        color='secondary'
        onClick={handleOpen}
        sx={{ justifyContent: 'flex-start', px: 0 }}
        disabled={account.status !== COMPANY_BANK_ACCOUNT_STATUS_ENUM.OPEN}
      >
        <Radio checked={selectedValue === account.id} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Stack direction='column' style={{ alignItems: 'flex-start' }}>
            <Typography variant='body2' fontWeight={600}>
              {accountName}
            </Typography>
            <UserMenuAccountItemBalance accountId={account.id} />
            <FeatureTemp user={user}>
              <span>
                {account.type} - {account.code} -{' '}
                {getProviderName(account.code)}
              </span>
            </FeatureTemp>
          </Stack>
          <Stack
            display='flex'
            flexDirection='column'
            alignItems='flex-end'
            width='120px'
          >
            <UserMenuCompanyBankAccountStatus account={account} />
          </Stack>
        </Box>
      </ActionButton>
      <UserMenuConfirmDefaultAccountModal
        open={open}
        onClose={handleClose}
        account={account}
      />
    </>
  );
};

export default UserMenuAccountItem;
