import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SwipeableDialogActions,
  SwipeableDialogModal,
  SwipeableDialogTitle,
  SwipeableDialogContent,
  ActionButton,
} from '@woovi/ui';

import { routeToMain } from '../../router/utils/index.tsx';

type PaymentAuthenticatorTOTPRequiredModalProps = {
  children?: ReactNode;
};

const PaymentAuthenticatorTOTPRequiredModal = ({
  children,
}: PaymentAuthenticatorTOTPRequiredModalProps): ReactNode => {
  const { t } = useTranslation();

  return (
    <>
      {children}
      <SwipeableDialogModal>
        <SwipeableDialogModal>
          <SwipeableDialogTitle>
            {t('Enable two-factor authentication via App')}
          </SwipeableDialogTitle>
          <SwipeableDialogContent>
            <Typography>
              {t(
                'For your safety, to carry out this action it is necessary activating two-factor authentication through the application.',
              )}
            </Typography>
          </SwipeableDialogContent>
          <SwipeableDialogActions>
            <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                href='https://developers.openpix.com.br/docs/security/security-user-mfa'
                color='secondary'
              >
                {t('How can I activate it?')}
              </Link>
              <ActionButton href={routeToMain('me.account.edit')}>
                {t('Activate')}
              </ActionButton>
            </Stack>
          </SwipeableDialogActions>
        </SwipeableDialogModal>
      </SwipeableDialogModal>
    </>
  );
};

export default PaymentAuthenticatorTOTPRequiredModal;
