import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

import type { RouteType } from '../../utils/index.tsx';

const PATH = '/home/permissions';

const permissionsRoutes: RouteType[] = [
  {
    name: 'users.master.invite',
    path: `${PATH}/users/invite`,
    labelHeader: ({ t }) => t('Users'),
    component: loadable(
      () =>
        import(
          '../../../components/user/userMaster/userMasterInvite/UserMasterInviteForm.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'users.master.invite.details',
    path: `${PATH}/users/invite/:id/details`,
    labelHeader: ({ t }) => t('Companies'),
    label: ({ t }) => t('Companies'),
    component: loadable(
      () =>
        import(
          '../../../components/user/userMaster/userMasterInvite/UserMasterInviteDetails.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [],
  },
  {
    name: 'permissions.users',
    path: `${PATH}/tabs/`,
    labelHeader: ({ t }) => t('Permissions'),
    component: loadable(() => import('../../../components/user/UserDetails.tsx')),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.users.list',
        path: `${PATH}/tabs/users-list`,
        labelHeader: ({ t }) => t('Users'),
        component: loadable(
          () => import('../../../components/user/UserListAll.tsx'),
        ),
        label: ({ t }) => t('Users'),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
      {
        name: 'permissions.users.list.new',
        path: `${PATH}/tabs/users/list`,
        labelHeader: ({ t }) => t('Users'),
        component: loadable(
          () => import('../../../components/user/userMaster/UserMasterListAll.tsx'),
        ),
        label: ({ t }) => t('Users'),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [{ $and: [MODULES.PERMISSIONS] }],
      },
      {
        name: 'permissions.users.invites',
        path: `${PATH}/tabs/users/invites`,
        labelHeader: ({ t }) => t('Invites'),
        label: ({ t }) => t('Invites'),
        component: loadable(
          () =>
            import(
              '../../../components/user/userMaster/userMasterInvite/UserMasterInviteListAll.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [],
      },
      {
        name: 'permissions.all.list',
        path: `${PATH}/tabs/permissions-list`,
        labelHeader: ({ t }) => t('Permissions List'),
        label: ({ t }) => t('Permissions List'),
        component: loadable(
          () => import('../../../components/accessControl/roles/RoleList.tsx'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
      {
        name: 'permissions.all.groups',
        path: `${PATH}/tabs/groups-list`,
        labelHeader: ({ t }) => t('Permission Groups'),
        label: ({ t }) => t('Permission Groups'),
        component: loadable(
          () =>
            import('../../../components/accessControl/roleGroup/RoleGroupList.tsx'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
    ],
  },

  {
    name: 'permissions.user',
    path: `${PATH}/user/:id`,
    labelHeader: ({ t }) => t('User Permissions'),
    component: loadable(
      () =>
        import('../../../components/accessControl/userRoles/UserRoleDetails.tsx'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.user.edit',
        path: `${PATH}/user/:id/edit`,
        labelHeader: ({ t }) => t('Edit User'),
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        exact: true,
        component: loadable(
          () => import('../../../components/user/edit/UserEdit.tsx'),
        ),
        label: ({ t }) => t('Data'),
      },
      {
        name: 'permissions.user.session',
        path: `${PATH}/user/:id/session`,
        labelHeader: ({ t }) => t('Sessions'),
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        exact: true,
        component: loadable(
          () => import('../../../components/user/session/UserSessionTokenList.tsx'),
        ),
        label: ({ t }) => t('Sessions'),
      },
    ],
  },
  {
    name: 'permissions.list.detail',
    path: `${PATH}/role/:role`,
    labelHeader: ({ t }) => t('Permissions List'),
    component: loadable(
      () => import('../../../components/accessControl/roles/RoleListDetails.tsx'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.list.users',
        path: `${PATH}/role/:role`,
        labelHeader: ({ t }) => t('Permissions List'),
        component: loadable(
          () => import('../../../components/accessControl/roles/RoleListUsers.tsx'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
        label: ({ t }) => t('Users'),
      },
    ],
  },
  {
    name: 'permissions.groups.add',
    path: `${PATH}/groups/add`,
    labelHeader: ({ t }) => t('Permissions Group'),
    component: loadable(
      () => import('../../../components/accessControl/roleGroup/RoleGroupAdd.tsx'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
  },
  {
    name: 'permissions.groups.detail',
    path: `${PATH}/group/:id`,
    labelHeader: ({ t }) => t('Group Permissions'),
    component: loadable(
      () =>
        import('../../../components/accessControl/roleGroup/RoleGroupDetail.tsx'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'permissions.groups.users',
        path: `${PATH}/group/:id/users`,
        labelHeader: ({ t }) => t('Group Permissions'),
        component: loadable(
          () =>
            import(
              '../../../components/accessControl/roleGroup/RoleGroupUsersList.tsx'
            ),
        ),
        exact: false,
        label: ({ t }) => t('Users'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
      {
        name: 'permissions.groups.edit',
        path: `${PATH}/group/:id/edit`,
        labelHeader: ({ t }) => t('Group Permissions'),
        component: loadable(
          () =>
            import('../../../components/accessControl/roleGroup/RoleGroupEdit.tsx'),
        ),
        exact: true,
        label: ({ t }) => t('Permissions'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
      {
        name: 'permissions.groups.settings',
        path: `${PATH}/group/:id/settings`,
        labelHeader: ({ t }) => t('Group Permissions'),
        component: loadable(
          () =>
            import(
              '../../../components/accessControl/roleGroup/RoleGroupSettings.tsx'
            ),
        ),
        exact: false,
        label: ({ t }) => t('Settings'),
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.PERMISSIONS, MODULES.OPEN_PIX],
      },
    ],
  },
];

export default permissionsRoutes;
