import { graphql, readInlineData } from 'react-relay';

import type { useProviderFeatures_account$key } from './__generated__/useProviderFeatures_account.graphql.ts';
import { accountHasProviderFeature } from './accountHasProviderFeature.tsx';
import { accountHasProviderFeatures } from './accountHasProviderFeatures.tsx';

export type HasProviderFeatureFn = (features: string) => boolean;

export type HasProviderFeaturesFn = (features: string[]) => boolean;

export type UseProviderFeaturesReturn = {
  providerFeatures?: readonly string[];
  hasProviderFeature: HasProviderFeatureFn;
  hasProviderFeatures: HasProviderFeaturesFn;
};

export const useProviderFeatures = (
  accountRef: useProviderFeatures_account$key | undefined | null,
): UseProviderFeaturesReturn => {
  const account = readInlineData<useProviderFeatures_account$key>(
    graphql`
      fragment useProviderFeatures_account on CompanyBankAccount @inline {
        providerFeatures
      }
    `,
    accountRef,
  );

  return {
    providerFeatures: account?.providerFeatures,
    hasProviderFeature: (feature: string) =>
      accountHasProviderFeature(account, feature),
    hasProviderFeatures: (features: string[]) =>
      accountHasProviderFeatures(account, features),
  };
};
