import { CircularProgress, Button } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import _Icon from '../icons/Icon.tsx';

type StyledButtonProps = {
  bgColor?: string;
};

export type ActionButtonProps = {
  icon?: string;
  label?: string;
  link?: string;
  external?: boolean;
  loading?: boolean;
} & ButtonProps &
  StyledButtonProps;

const ActionButton = ({
  icon,
  size = 'medium',
  variant = 'contained',
  color = 'primary',
  children,
  label,
  link,
  external = false,
  loading = false,
  ...props
}: ActionButtonProps): ReactNode => {
  const getLoading = () => {
    if (loading) {
      return {
        startIcon: <CircularProgress sx={{ color: 'inherit' }} size={12} />,
      };
    }

    return {};
  };

  const innerElement = (
    <>
      {!!icon && <_Icon>{icon}</_Icon>}
      {label ? label : children}
    </>
  );

  if (link) {
    if (external) {
      return (
        <Button
          target='_blank'
          href={link}
          color={color}
          variant={variant}
          size={size}
          disableElevation
          {...getLoading()}
          {...props}
        >
          {innerElement}
        </Button>
      );
    }

    return (
      <Button
        component={Link}
        to={link}
        color={color}
        variant={variant}
        size={size}
        disableElevation
        {...getLoading()}
        {...props}
      >
        {innerElement}
      </Button>
    );
  }

  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      disableElevation
      {...getLoading()}
      {...props}
    >
      {innerElement}
    </Button>
  );
};

export default ActionButton;
