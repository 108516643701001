import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/applications';

export const applicationRoutes: RouteType[] = [
  {
    name: 'applications.add',
    path: `${PATH}/add`,
    labelHeader: ({ t }) => t('Application - Add'),
    label: ({ t }) => t('Application - Add'),
    component: loadable(
      () => import('../../../components/applications/ApplicationAdd.tsx'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
  },
  {
    name: 'application.details',
    path: `${PATH}/detail/:id`,
    labelHeader: ({ t }) => t('Application Detail'),
    label: ({ t }) => t('Application Detail'),
    component: loadable(
      () => import('../../../components/applications/data/ApplicationRoot.tsx'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'application.details.data',
        path: `${PATH}/detail/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () => import('../../../components/applications/data/ApplicationData.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.CORE.APPLICATION,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.API_WEBHOOK,
        ],
        requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
      },
      {
        name: 'application.details.logs',
        path: `${PATH}/detail/:id/logs`,
        labelHeader: ({ t }) => t('Logs'),
        label: ({ t }) => t('Logs'),
        component: loadable(
          () => import('../../../components/applications/data/ApplicationLogs.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.CORE.APPLICATION,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.API_WEBHOOK,
        ],
        requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
      },
    ],
  },
  {
    name: 'applications.opencart3.add',
    path: `${PATH}/opencart3/add`,
    labelHeader: ({ t }) => t('OpenCart 3 - Add'),
    label: ({ t }) => t('OpenCart 3 - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationOneClickOpencart3.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.opencart4.add',
    path: `${PATH}/opencart/add`,
    labelHeader: ({ t }) => t('OpenCart 4 - Add'),
    label: ({ t }) => t('OpenCart 4 - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationOneClickOpenCart4.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.woocommerce.add.oneclick',
    path: `${PATH}/woocommerce/add/oneclick`,
    labelHeader: ({ t }) => t('WooCommerce - Add'),
    label: ({ t }) => t('WooCommerce - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationOneClickWoocommerce.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.woopixcreditcard.add.oneclick',
    path: `${PATH}/woocommerce-pix-credit-card/add/oneclick`,
    labelHeader: ({ t }) => t('WooCommerce - Add'),
    label: ({ t }) => t('WooCommerce - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationOneClickWoocommercePixCreditCard.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.magento1.add.oneclick',
    path: `${PATH}/magento1/add/oneclick`,
    labelHeader: ({ t }) => t('Magento1 - Add'),
    label: ({ t }) => t('Magento1 - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationOneClickMagento1.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.magento2.add.oneclick',
    path: `${PATH}/magento2/add/oneclick`,
    labelHeader: ({ t }) => t('Magento2 - Add'),
    label: ({ t }) => t('Magento2 - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationOneClickMagento2.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.magento1.add',
    path: `${PATH}/magento1/add`,
    labelHeader: ({ t }) => t('Magento1 - Add'),
    label: ({ t }) => t('Magento1 - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationMagento1Add.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.magento2.add',
    path: `${PATH}/magento2/add`,
    labelHeader: ({ t }) => t('Magento1 - Add'),
    label: ({ t }) => t('Magento1 - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationMagento2Add.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.vtex.add',
    path: `${PATH}/vtex/add`,
    labelHeader: ({ t }) => t('Plugin VTEX - Add'),
    label: ({ t }) => t('Plugin VTEX - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationVtexAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX, MODULES.VTEX],
  },
  {
    name: 'applications.wake.add',
    path: `${PATH}/wake/add`,
    labelHeader: ({ t }) => t('Plugin Wake - Add'),
    label: ({ t }) => t('Plugin Wake - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationWakeAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.nuvemshop.add',
    path: `${PATH}/nuvemshop/add`,
    labelHeader: ({ t }) => t('Plugin Nuvemshop - Add'),
    label: ({ t }) => t('Plugin Nuvemshop - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/nuvemshop/IntegrationNuvemshopAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
  },
  {
    name: 'applications.js.add',
    path: `${PATH}/js/add`,
    labelHeader: ({ t }) => t('Plugin Javascript - Add'),
    label: ({ t }) => t('Plugin Javascript - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationJavascriptAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.api.add',
    path: `${PATH}/api/add`,
    labelHeader: ({ t }) => t('API - Add'),
    label: ({ t }) => t('API - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationApiAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.oracle.add',
    path: `${PATH}/oracle/add`,
    labelHeader: ({ t }) => t('Oracle - Add'),
    label: ({ t }) => t('Oracle - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationOracleAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.php.add',
    path: `${PATH}/php/add`,
    labelHeader: ({ t }) => t('PHP - Add'),
    label: ({ t }) => t('PHP - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationPhpAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.ruby.add',
    path: `${PATH}/ruby/add`,
    labelHeader: ({ t }) => t('Ruby - Add'),
    label: ({ t }) => t('Ruby - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationRubyAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.shell.add',
    path: `${PATH}/shell/add`,
    labelHeader: ({ t }) => t('SHELL - Add'),
    label: ({ t }) => t('SHELL - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationShellAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.nodejs.add',
    path: `${PATH}/nodejs/add`,
    labelHeader: ({ t }) => t('NodeJs - Add'),
    label: ({ t }) => t('NodeJs - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationNodeAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.csharp.add',
    path: `${PATH}/csharp/add`,
    labelHeader: ({ t }) => t('C# - Add'),
    label: ({ t }) => t('C# - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationCsharpAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },

  {
    name: 'applications.python.add',
    path: `${PATH}/python/add`,
    labelHeader: ({ t }) => t('Python - Add'),
    label: ({ t }) => t('Python - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationPythonAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.go.add',
    path: `${PATH}/go/add`,
    labelHeader: ({ t }) => t('Go - Add'),
    label: ({ t }) => t('Go - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationShellAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },

  {
    name: 'applications.java.add',
    path: `${PATH}/java/add`,
    labelHeader: ({ t }) => t('Java - Add'),
    label: ({ t }) => t('Java - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationJavaAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },

  {
    name: 'applications.delphi.add',
    path: `${PATH}/delphi/add`,
    labelHeader: ({ t }) => t('Delphi - Add'),
    label: ({ t }) => t('Delphi - Add'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/IntegrationDelphiAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.whatsapp.add',
    path: `${PATH}/whatsapp/add`,
    labelHeader: 'WhatsApp',
    label: 'WhatsApp',
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/whatsApp/IntegrationWhatsAppAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.mqtt.add',
    path: `${PATH}/mqtt/add`,
    labelHeader: ({ t }) => t('MQTT - Pix Machine'),
    label: ({ t }) => t('MQTT - Pix Machine'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/mqtt/IntegrationMqttAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
  },
  {
    name: 'applications.meta.add',
    path: `${PATH}/meta/add`,
    labelHeader: ({ t }) => t('Facebook Meta Pixel'),
    label: ({ t }) => t('Facebook Meta Pixel'),
    component: loadable(
      () =>
        import(
          '../../../components/integration/meta-pixel/IntegrationMetaPixelSettings.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.nfe.add',
    path: `${PATH}/nfe/add`,
    labelHeader: ({ t }) => t('Eletronic invoice'),
    label: ({ t }) => t('Eletronic invoice'),
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/nfe/IntegrationNfeAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX, MODULES.INVOICE],
  },
  {
    name: 'applications.shopify.add',
    path: `${PATH}/shopify/add`,
    labelHeader: 'Shopify',
    label: 'Shopify',
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/shopify/IntegrationShopifyAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.shopify.auto.add',
    path: `${PATH}/shopify/auto/add`,
    labelHeader: 'Shopify',
    label: 'Shopify',
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/shopify/IntegrationShopifyAutoAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'applications.wabiz.add',
    path: `${PATH}/wabiz/add`,
    labelHeader: 'Wabiz',
    label: 'Wabiz',
    component: loadable(
      () =>
        import(
          '../../../components/applications/integration/wabiz/IntegrationWabizAdd.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.CORE.APPLICATION,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
];
