import loadable from '@loadable/component';

import type { RouteType } from '../utils/index.tsx';

const PATH = '/home/search';

const searchRoutes: RouteType[] = [
  {
    name: 'search',
    path: PATH,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple.tsx'),
    ),
    exact: false,
    labelHeader: ({ t }) => `${t('Search')}`,
    routes: [
      {
        labelHeader: ({ t }) => t('Search Detail'),
        label: ({ t }) => t('Search'),
        name: 'search.list',
        path: PATH,
        component: loadable(
          () => import('../../components/search/OmniSearchBar.tsx'),
        ),
        exact: true,
      },
    ],
  },
];

export default searchRoutes;
