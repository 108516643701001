import TrendingUpIcon from '@mui/icons-material/TrendingUpOutlined';
import type { ReactNode } from 'react';

import { ADMIN_ROLE, ROLES } from '@woovi/roles';

import { routeToMain } from '../../../../router/utils/index.tsx';

type TransactionsProps = {
  t: (key: string) => string;
  hasRole: (roles: string[]) => boolean;
  disputeCount?: number;
};

type TransactionsItem = {
  label: string;
  icon: ReactNode;
  link?: string;
  notificationCounter?: number;
};

export const transactions = (props: TransactionsProps): TransactionsItem[] => {
  const t = props.t;

  const canSeeReport = props.hasRole([
    ADMIN_ROLE,
    ROLES.OPEN_PIX.ADMIN.ALL,
    ROLES.OPEN_PIX.TRANSACTIONS,
    ROLES.OPEN_PIX.REFUND,
    ROLES.OPEN_PIX.REPORT,
  ]);

  const transactionRoute = {
    label: t('Statement'),
    icon: <TrendingUpIcon />,
    notificationCounter: props.disputeCount || 0,
  };

  if (canSeeReport) {
    return [{ ...transactionRoute, link: routeToMain('transaction.report') }];
  }

  return [
    {
      ...transactionRoute,
      link: routeToMain('transaction.list'),
    },
  ];
};
