import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/rewards';

export const rewardsRoutes: RouteType[] = [
  {
    name: 'rewards',
    path: `${PATH}/tab`,
    labelHeader: ({ t }) => t('Rewards'),
    label: ({ t }) => t('Rewards'),
    component: loadable(
      () => import('../../components/rewards/RewardsHeader.tsx'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.TRANSACTIONS,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.CASHBACK],
    routes: [
      {
        name: 'rewards.overview',
        path: `${PATH}/tab/overview`,
        labelHeader: ({ t }) => t('Overview'),
        label: ({ t }) => t('Overview'),
        component: loadable(
          () => import('../../components/rewards/RewardsOverview.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.CASHBACK],
      },
      {
        name: 'rewards.cashback.statement',
        path: `${PATH}/tab/cashback/statement/`,
        labelHeader: ({ t }) => t('Cashback Statement'),
        label: ({ t }) => t('Cashback Statement'),
        component: loadable(
          () => import('../../components/rewards/cashback/CashbackList.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.CASHBACK],
      },
      {
        name: 'rewards.settings',
        path: `${PATH}/tab/settings`,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () => import('../../components/rewards/settings/RewardsSettings.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.CASHBACK],
      },
      {
        name: 'rewards.tab.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () =>
            import('../../components/rewards/tutorial/RewardsTutorialTab.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.CASHBACK],
      },
      {
        name: 'rewards.tab.cashback.plugin',
        path: `${PATH}/tab/cashback/plugin`,
        labelHeader: ({ t }) => t('Cashback plugin'),
        label: ({ t }) => t('Cashback plugin'),
        component: loadable(
          () => import('../../components/plugin/PluginDemoCashback.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.CASHBACK, MODULES.TEMP],
      },
    ],
  },
  {
    name: 'rewards.tutorial',
    path: `${PATH}/tutorial`,
    labelHeader: ({ t }) => t('Tutorial'),
    label: ({ t }) => t('Tutorial'),
    component: loadable(
      () => import('../../components/rewards/tutorial/RewardsTutorial.tsx'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CASHBACK],
  },
];
