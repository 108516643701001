import dot from 'dot-object';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { stringToValue } from '@woovi/router';
import { queryString } from '@woovi/shared';

import { useQuery } from './useQuery.tsx';

const { stringify } = queryString;

const mapObjectKeys = <T extends Record<number | string | symbol, string>>(
  value: T,
) => {
  const mappedObj: Record<string | number | symbol, unknown> = {};

  Object.keys(value).map((key: keyof T) => {
    if (Number(value[key]) && parseFloat(value[key])) {
      mappedObj[key] = parseFloat(value[key]);
    }
  });

  return mappedObj;
};

export const useFiltersFromLocation = <
  T extends Record<number | string | symbol, unknown>,
>(
  filterKeys: string[],
  notParseKeys?: string[],
) => {
  const queryParams = useQuery();
  const history = useHistory();

  const filters = useMemo<T>(() => {
    return filterKeys
      .map((key) => {
        const value = stringToValue(dot.pick(key, queryParams), key);

        if (typeof value !== 'boolean' && !value) {
          return {
            [key]: undefined,
          };
        }

        if (
          notParseKeys &&
          typeof value !== 'boolean' &&
          Number(value) &&
          notParseKeys.indexOf(key) === -1
        ) {
          return {
            [key]: Number(value),
          };
        }

        if (Array.isArray(value)) {
          return { [key]: value };
        }

        if (typeof value === 'object') {
          return mapObjectKeys(
            value as Record<number | string | symbol, string>,
          );
        }

        return { [key]: value };
      })
      .reduce((acc, filter) => ({ ...acc, ...filter }), {}) as T;
  }, [queryParams]);

  const setLocationFilters = useCallback((newFilters: Partial<T>) => {
    const _filters = Object.entries(newFilters)
      .map(([key, value]) => ({ [key]: stringToValue(value as string, key) }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});

    const foo = dot.dot(_filters) as unknown as T;

    const { pathname } = location;
    const search = stringify(foo);

    history.replace({ pathname, search });
  }, []);

  return { filters, setLocationFilters } as const;
};
