import React from 'react';

import { useSwipeableDialogContext } from './SwipeableDialog.tsx';
import { composeEventHandlers } from '../utils/composeEventHandlers.ts';

type SwipeableDialogCloseProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const SwipeableDialogClose = (props: SwipeableDialogCloseProps) => {
  const { setOpen } = useSwipeableDialogContext();
  const { children, onClick } = props;

  const handleDialogClose = () => {
    setOpen(false);
  };

  return React.cloneElement(children as React.ReactElement, {
    onClick: composeEventHandlers(handleDialogClose, onClick),
  });
};

SwipeableDialogClose.displayName = 'SwipeableDialogClose';
