export const CARD_PROCESSING_PROVIDERS_ENUM = {
  PAGARME: 'PAGARME',
  TEST: 'TEST',
} as const;
export type CARD_PROCESSING_PROVIDERS_ENUM =
  (typeof CARD_PROCESSING_PROVIDERS_ENUM)[keyof typeof CARD_PROCESSING_PROVIDERS_ENUM];
export const getCreditCardProviderName = (
  provider: CARD_PROCESSING_PROVIDERS_ENUM,
): string =>
  ({
    [CARD_PROCESSING_PROVIDERS_ENUM.PAGARME]: 'Pagarme',
    [CARD_PROCESSING_PROVIDERS_ENUM.TEST]: 'Test',
  })[provider];
