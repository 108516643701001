/**
 * @generated SignedSource<<ca847ba758d196eedb605770da085b39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuCompanyBankAccountStatus_account$data = {
  readonly status: string | null | undefined;
  readonly " $fragmentType": "UserMenuCompanyBankAccountStatus_account";
};
export type UserMenuCompanyBankAccountStatus_account$key = {
  readonly " $data"?: UserMenuCompanyBankAccountStatus_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuCompanyBankAccountStatus_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuCompanyBankAccountStatus_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "CompanyBankAccount",
  "abstractKey": null
};

(node as any).hash = "cf5b267173d6943c688dcf638ca1d6fe";

export default node;
