/**
 * @generated SignedSource<<29dd0b4fda07c3c731f1015a203f9711>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type User_me$data = {
  readonly company: {
    readonly name: string | null | undefined;
    readonly taxID: {
      readonly taxID: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string | null | undefined;
  readonly profileImage: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly userMaster: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenu_me">;
  readonly " $fragmentType": "User_me";
};
export type User_me$key = {
  readonly " $data"?: User_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"User_me">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "User_me",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenu_me"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxID",
          "kind": "LinkedField",
          "name": "taxID",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxID",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "profileImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMaster",
      "kind": "LinkedField",
      "name": "userMaster",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "8674df55258967052a96e3545240e203";

export default node;
