import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import { Rnd } from 'react-rnd';

import { WindowPopperTransition } from './WindowPopperTransition.tsx';
import BoxFlex from '../mui/BoxFlex.tsx';

import type { ReactNode } from 'react';

type WindowPopperProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  x?: number;
  y?: number;
  cancelDragOnContent?: boolean;
};

export const WindowPopper = ({
  open,
  onClose,
  title,
  children,
  cancelDragOnContent = false,
  x = 0,
  y = 0,
  width = 500,
  height = 500,
  minWidth = 200,
  minHeight = 200,
}: WindowPopperProps): ReactNode => {
  const theme = useTheme();

  if (!open) {
    return null;
  }

  const getCancelDrag = () => {
    if (!cancelDragOnContent) {
      return {};
    }

    return { cancel: '.no-drag' };
  };

  return (
    <Rnd
      default={{
        x,
        y,
        width,
        height,
      }}
      style={{ zIndex: theme.zIndex.appBar + 1 }}
      minWidth={minWidth}
      minHeight={minHeight}
      bounds='window'
      {...getCancelDrag()}
    >
      <WindowPopperTransition in={open}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[3],
            borderRadius: '4px',
            flexDirection: 'column',
          }}
        >
          <BoxFlex
            sx={{
              py: 1,
              pl: 2,
              pr: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle1' fontWeight={600} color='black'>
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <GridCloseIcon />
            </IconButton>
          </BoxFlex>
          <Divider />
          <Box
            className='no-drag'
            sx={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            {children}
          </Box>
        </Box>
      </WindowPopperTransition>
    </Rnd>
  );
};
