import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';
import { useLocation } from 'react-router-dom';

import { USER_MASTER_REQUIRED_FIELDS_ENUM } from '@woovi/shared';

import type { useUserRequiredFields_userMaster$key } from './__generated__/useUserRequiredFields_userMaster.graphql.ts';

export const useUserRequiredFields = (
  userMasterRef: useUserRequiredFields_userMaster$key,
): boolean => {
  const location = useLocation();

  const meMaster = readInlineData<useUserRequiredFields_userMaster$key>(
    graphql`
      fragment useUserRequiredFields_userMaster on UserMaster @inline {
        requiredFields
      }
    `,
    userMasterRef,
  );

  const userMasterShouldChangeTaxID = meMaster
    ? meMaster.requiredFields?.includes(USER_MASTER_REQUIRED_FIELDS_ENUM.TAXID)
    : false;

  useEffect(() => {
    if (userMasterShouldChangeTaxID) {
      const next = location.pathname ? `?next=${location.pathname}` : '';

      window.location.href = `/login/user/should-confirm-taxid${next}`;
    }
  }, [userMasterShouldChangeTaxID]);

  return !!userMasterShouldChangeTaxID;
};
