import type { ReactNode } from 'react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { SwipeableDialog, SwipeableDialogTrigger } from '@woovi/ui';

import type { AccountActivateWrapper_user$key } from './__generated__/AccountActivateWrapper_user.graphql.ts';
import { AccountActivateTutorialModal } from './AccountActivateTutorialModal.tsx';
import { useAccountActivateWrapper } from './useAccountActivateWrapper.tsx';

export type AccountActivateWrapperProps = {
  children: ReactNode;
  user: AccountActivateWrapper_user$key;
};

export const AccountActivateWrapper = ({
  children,
  ...props
}: AccountActivateWrapperProps): ReactNode => {
  const user = useFragment<AccountActivateWrapper_user$key>(
    graphql`
      fragment AccountActivateWrapper_user on User {
        ...useAccountActivateWrapper_user
      }
    `,
    props.user,
  );

  const { isBaasAccount } = useAccountActivateWrapper(user);

  if (isBaasAccount) {
    return children;
  }

  const clonedChildren = React.cloneElement(children as React.ReactElement, {
    onClick: undefined,
    href: undefined,
    link: undefined,
  });

  return (
    <SwipeableDialog>
      <SwipeableDialogTrigger>{clonedChildren}</SwipeableDialogTrigger>
      <AccountActivateTutorialModal />
    </SwipeableDialog>
  );
};
