import type { GridColDef } from '@mui/x-data-grid-pro';

import { GRID_BOOLEAN_CUSTOM_COL_DEF } from './cellTypeBoolean.tsx';
import { GRID_CURRENCY_CUSTOM_COL_DEF } from './cellTypeCurrency.ts';
import { GRID_DATE_CUSTOM_COL_DEF } from './cellTypeDate.ts';
import { GRID_DATETIME_CUSTOM_COL_DEF } from './cellTypeDatetime.ts';
import { cellTypeTag } from './cellTypeTag.tsx';
import { cellTypeTaxID } from './cellTypeTaxID.tsx';

export const wrapColumnTypes = (columns: readonly GridColDef[]) =>
  columns.map((column) => {
    if (column.type) {
      const types = {
        date: { ...GRID_DATE_CUSTOM_COL_DEF, ...column },
        datetime: { ...GRID_DATETIME_CUSTOM_COL_DEF, ...column },
        taxID: { ...cellTypeTaxID, ...column },
        boolean: { ...GRID_BOOLEAN_CUSTOM_COL_DEF, ...column },
        currency: { ...GRID_CURRENCY_CUSTOM_COL_DEF, ...column },
        tag: { ...cellTypeTag(), ...column },
      };

      if (column.type in types) {
        return types[column.type];
      }

      return column;
    }

    return column;
  });
