import type { GridColTypeDef, GridValidRowModel } from '@mui/x-data-grid-pro';

import Tag from '../tag/Tag.tsx';

export const cellTypeTag = <R extends GridValidRowModel>(
  onClick?: (tag: string) => unknown,
  breakByColon = true,
): GridColTypeDef<unknown, R> => ({
  extendType: 'string',
  type: 'string',
  renderCell: ({ value: tags }) => {
    if (!Array.isArray(tags) || tags.length === 0) {
      return '-';
    }

    return tags.map((tag, index) => (
      <Tag
        key={index}
        label={tag}
        breakByColon={breakByColon}
        onClick={(e) => {
          e.stopPropagation();

          onClick && onClick(tag);
        }}
      />
    ));
  },
});
