import type {
  CacheConfig,
  RequestParameters,
  UploadableMap,
  Variables,
} from 'relay-runtime';
import { Observable } from 'relay-runtime';

import { networkCacheSink } from './networkCacheSink.tsx';

// Define a function that fetches the results of an request (query/mutation/etc)
// and returns its results as a Promise:
export const networkFetchObservable = (
  request: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables?: UploadableMap | null,
) => {
  return Observable.create((sink) => {
    networkCacheSink(request, variables, cacheConfig, uploadables, sink);
  });
};
