import {
  accountHasProviderFeature,
  type AccountWithProviderFeatures,
} from './accountHasProviderFeature.tsx';

export const accountHasProviderFeatures = <
  T extends AccountWithProviderFeatures,
>(
  account: T | undefined | null,
  features: string[],
) => {
  return features.some((feature) =>
    accountHasProviderFeature(account, feature),
  );
};
