import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

import { APP_PATH } from './appPath.tsx';

export const paymentRoutes: RouteType[] = [
  {
    name: 'payments',
    path: `${APP_PATH}/payments`,
    component: loadable(
      () => import('../../../components/payment/PaymentHeader.tsx'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('OpenPix'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.PAYMENTS,
      ROLES.PIX_OUT.BILL.REQUEST,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'withdraw.list',
        path: `${APP_PATH}/payments/withdraws`,
        labelHeader: ({ t }) => t('Withdraws'),
        label: ({ t }) => t('Withdraws'),
        component: loadable(
          () => import('../../../components/payment/WithdrawList.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.PAYMENTS,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'payments.list',
        path: `${APP_PATH}/payments/list`,
        labelHeader: ({ t }) => t('Payments'),
        label: ({ t }) => t('Payments'),
        component: loadable(
          () => import('../../../components/movement/MovementList.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.PIX_OUT.BILL.REQUEST,
          ROLES.PIX_OUT.BILL.VIEW,
        ],
      },
      {
        name: 'payments.tutorial',
        path: `${APP_PATH}/payments/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () =>
            import(
              '../../../components/movement/tutorial/MovementTutorial.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.PAYMENTS,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'payments.settings',
        path: `${APP_PATH}/payments/settings`,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () => import('../../../components/payment/WithdrawSettings.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.TRANSACTIONS,
          ROLES.OPEN_PIX.REFUND,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
  {
    name: 'payment.details',
    path: `${APP_PATH}/payment/:id`,
    labelHeader: ({ t }) => t('Payment'),
    label: ({ t }) => t('Payment'),
    component: loadable(
      () => import('../../../components/payment/details/PaymentDetails.tsx'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.PAYMENTS,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'payment.details.data',
        path: `${APP_PATH}/payment/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () => import('../../../components/payment/details/PaymentData.tsx'),
        ),
        exact: true,
        requiredFeatures: [MODULES.OPEN_PIX],
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.PAYMENTS,
        ],
      },
    ],
  },
  {
    name: 'pay.details',
    path: `${APP_PATH}/pay/details/:id`,
    labelHeader: ({ t }) => t('Payment'),
    label: ({ t }) => t('Payment'),
    component: loadable(
      () => import('../../../components/movement/details/MovementDetails.tsx'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.PIX_OUT.BILL.VIEW],
    routes: [
      {
        name: 'pay.details.data',
        path: `${APP_PATH}/pay/details/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () => import('../../../components/movement/details/MovementData.tsx'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.PIX_OUT.BILL.VIEW],
      },
    ],
  },
  {
    name: 'pay.create',
    path: `${APP_PATH}/pay/create`,
    component: loadable(
      () => import('../../../components/movement/create/MovementCreate.tsx'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.PAYMENTS,
    ],
    requiredFeatures: [MODULES.PIX_OUT],
  },
  {
    name: 'pay.create.batch',
    path: `${APP_PATH}/pay/create-batch`,
    component: loadable(
      () => import('../../../components/movement/import/MovementImport.tsx'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.PAYMENTS,
    ],
    requiredFeatures: [MODULES.PIX_OUT],
  },
  {
    name: 'pay.bill.create',
    path: `${APP_PATH}/pay/bill/create`,
    component: loadable(
      () => import('../../../components/movement/bill/MovementBillCreate.tsx'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.PIX_OUT.BILL.REQUEST,
    ],
    requiredFeatures: [MODULES.PAY_BILLS, MODULES.PIX_OUT],
  },
];
