import { graphql } from 'react-relay';

import { ADMIN_ROLE, MODULES } from '@woovi/roles';

import type { permissionsUserFragment$data } from './__generated__/permissionsUserFragment.graphql.ts';
import { getRouteFromPath } from '../router/utils/index.tsx';

export type RequiredRolesType = string[];

export const PERMISSION_OPERATORS = {
  $or: '$or',
  $and: '$and',
};

export type RequiredFeatureType =
  | string
  | { [operation in keyof typeof PERMISSION_OPERATORS]: string[] };

export type RequiredProjectType =
  | string
  | { [operation in keyof typeof PERMISSION_OPERATORS]: string[] };

export type RequiredFeaturesType = RequiredFeatureType[];
export type RequiredProjectsTypes = RequiredProjectType[];

type Permissions = {
  requiredRoles: RequiredRolesType;
  requiredFeatures: RequiredFeaturesType;
};

// @deprecated - avoid using this with @relay(mask: false), prefer `userPermission` hook

graphql`
  fragment permissionsUserFragment on User {
    isAdmin
    allRoles
    company {
      features
      integrations {
        edges {
          node {
            type
            status
          }
        }
      }
      projectType
    }
  }
`;

const getArrayOfUniqueByKey = (array: Record<string, unknown>[], key: string) =>
  array
    .map((obj) => obj[key])
    .filter((value, index, self) => self.includes(value))
    .reduce((acc, cur) => [...acc, ...cur], []);

export const hasMatchedValueInArray = (
  valueArray: string[],
  testArray: string[],
): boolean =>
  valueArray
    .map((value) => testArray.includes(value))
    .reduce((acc: boolean, cur: boolean): boolean => acc || cur, false);

export const hasRole = (
  user: permissionsUserFragment$data,
  requiredRoles: RequiredRolesType = [],
): boolean => {
  if (!user) {
    return false;
  }

  const { allRoles } = user;

  if (allRoles.includes(ADMIN_ROLE)) {
    return true;
  }

  if (requiredRoles.length === 0) {
    return true;
  }

  return hasMatchedValueInArray(requiredRoles, allRoles);
};

const isModuleEnabled = (module: string, features: string[]): boolean => {
  const allModules = [...features];

  return allModules.includes(module);
};

const isTempRequired = (
  requiredFeatures: RequiredFeaturesType = [],
): boolean => {
  return requiredFeatures.reduce((acc, featureOperation) => {
    if (typeof featureOperation === 'object') {
      const requiredModules = featureOperation.$or
        ? featureOperation.$or
        : featureOperation.$and;

      return acc || isTempRequired(requiredModules);
    } else {
      return featureOperation === MODULES.TEMP;
    }
  }, false);
};

const isFeatureOperationEnabled = (
  featureOperation: RequiredFeatureType,
  allModules: string[],
): boolean => {
  if (typeof featureOperation !== 'object') {
    return isModuleEnabled(featureOperation, allModules);
  }

  const empty = featureOperation.$or ? false : true;
  const concat = featureOperation.$or
    ? (a: boolean, b: boolean) => a || b
    : (a: boolean, b: boolean) => a && b;

  const requiredOperations = featureOperation.$or
    ? featureOperation.$or
    : featureOperation.$and;

  return requiredOperations.reduce(
    (acc, operation) =>
      concat(acc, isFeatureOperationEnabled(operation, allModules)),
    empty,
  );
};

export const hasProjectType = (
  user: permissionsUserFragment$data,
  requiredProjectsTypes: RequiredProjectsTypes,
) => {
  if (requiredProjectsTypes.length === 0) {
    return true;
  }

  if (!user) {
    return false;
  }

  const { projectType } = user.company;

  const hasProjectType =
    requiredProjectsTypes.filter((pType) => pType === projectType).length > 0;

  return hasProjectType;
};

export const hasFeature = (
  user: permissionsUserFragment$data,
  requiredFeatures: RequiredFeaturesType = [],
): boolean => {
  if (!user) {
    return false;
  }

  const { features } = user.company;

  if (requiredFeatures.length === 0) {
    return true;
  }

  const allModules = [...features];

  const isTempEnabled = features.includes(MODULES.TEMP);

  const isTempNeeded = isTempRequired(requiredFeatures);

  if (isTempNeeded && !isTempEnabled) {
    return false;
  }

  for (const featureOperation of requiredFeatures) {
    if (isFeatureOperationEnabled(featureOperation, allModules)) {
      return true;
    }
  }

  return false;
};

export const hasPermission = (
  user: permissionsUserFragment$data,
  requiredFeatures?: RequiredFeaturesType,
  requiredRoles?: RequiredRolesType,
  requiredProjectsTypes: RequiredProjectsTypes = [],
): boolean =>
  hasFeature(user, requiredFeatures) &&
  hasRole(user, requiredRoles) &&
  hasProjectType(user, requiredProjectsTypes);

export const concatPermissions = (permissions: Permissions[]) => {
  const requiredFeatures = getArrayOfUniqueByKey(
    permissions,
    'requiredFeatures',
  );

  const requiredRoles = getArrayOfUniqueByKey(permissions, 'requiredRoles');

  return {
    requiredFeatures,
    requiredRoles,
  };
};

export const getPermissionsFromRoutes = (paths: string[]): Permissions[] =>
  paths.map((path) => {
    const { requiredRoles = [], requiredFeatures = [] } =
      getRouteFromPath(path);

    return {
      requiredRoles,
      requiredFeatures,
    };
  });

export const groupHasPermission = (
  me: permissionsUserFragment$data,
  permissions: Permissions[],
) => {
  return permissions.reduce(
    (acc, { requiredRoles, requiredFeatures }) =>
      acc || hasPermission(me, requiredFeatures, requiredRoles),
    false,
  );
};

export type HasIntegrationConditions = {
  status?: string;
  type?: string;
};

export const hasIntegration = (
  user: permissionsUserFragment$data,
  conditions: HasIntegrationConditions,
): boolean => {
  if (!user) {
    return false;
  }

  const testConditions = (args: Record<string, unknown>): boolean => {
    if (!conditions) {
      return true;
    }

    return Object.entries(conditions).reduce((acc, curr) => {
      const condition = curr[0];
      const value = curr[1];

      return acc && value === args[condition];
    }, true);
  };

  return !!user.company?.integrations?.edges?.find((edge) =>
    testConditions(edge?.node || {}),
  );
};
