export const PROVIDER_FEATURE_ENUM = {
  ACCOUNT_ADDITIONAL_INFO: 'ACCOUNT_ADDITIONAL_INFO',
  ACCOUNT_REQUIREMENT: 'ACCOUNT_REQUIREMENT',
  BAAS: 'BAAS',
  BAAS_ACCOUNT_PROVIDER: 'BAAS_ACCOUNT_PROVIDER',
  BALANCE: 'BALANCE',
  COBV_ADDRESS_REQUIRED: 'COBV_ADDRESS_REQUIRED',
  CREATE_STATIC_PIX_QR_CODE: 'CREATE_STATIC_PIX_QR_CODE',
  DEFAULT_ACCOUNT: 'DEFAULT_ACCOUNT',
  FEE: 'FEE',
  ITP: 'ITP',
  LIMITS: 'LIMITS',
  PAY_PIX_KEY_DIRECT: 'PAY_PIX_KEY_DIRECT',
  PIX_COB: 'PIX_COB',
  PIX_COBV: 'PIX_COBV',
  PIX_KEY: 'PIX_KEY',
  PIX_OUT: 'PIX_OUT',
  PIX_QR_CODE_DISABLE: 'PIX_QR_CODE_DISABLE',
  PIX_KEY_MANAGEMENT: 'PIX_KEY_MANAGEMENT',
  REFUND: 'REFUND',
  STATEMENT: 'STATEMENT',
  TEST: 'TEST',
  TRANSACTION_POOLING: 'TRANSACTION_POOLING',
  WALLET_PROVIDER: 'WALLET_PROVIDER',
  WITHDRAW: 'WITHDRAW',
  WITHDRAW_OPTION_10AM: 'WITHDRAW_OPTION_10AM',
  WITHDRAW_OPTION_7PM: 'WITHDRAW_OPTION_7PM',
  WITHDRAW_OPTION_6PM: 'WITHDRAW_OPTION_6PM',
} as const;
export type PROVIDER_FEATURE_ENUM =
  (typeof PROVIDER_FEATURE_ENUM)[keyof typeof PROVIDER_FEATURE_ENUM];
