import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';
import { useLocation } from 'react-router-dom';

import type { useShouldChangePassword_userMaster$key } from './__generated__/useShouldChangePassword_userMaster.graphql.ts';

export const useShouldChangePassword = (
  userMasterRef: useShouldChangePassword_userMaster$key,
): boolean => {
  const location = useLocation();

  const meMaster = readInlineData<useShouldChangePassword_userMaster$key>(
    graphql`
      fragment useShouldChangePassword_userMaster on UserMaster @inline {
        shouldChangePassword
      }
    `,
    userMasterRef,
  );

  const userMasterShouldChangePassword = meMaster?.shouldChangePassword;

  useEffect(() => {
    if (userMasterShouldChangePassword) {
      const next = location.pathname ? `?next=${location.pathname}` : '';

      window.location.href = `/login/user/should-change-password${next}`;
    }
  }, [userMasterShouldChangePassword]);

  return !!userMasterShouldChangePassword;
};
