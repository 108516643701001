/**
 * @generated SignedSource<<649abfeae70c6a6c0df9eb761140c293>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type IntegrationType = "MAGENTO1" | "MAGENTO2" | "META_PIXEL" | "NFEIO" | "OPENCART" | "SHOPIFY" | "WABIZ" | "WOOCOMMERCE" | "WOOCOMMERCE_PIX_CREDIT_CARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminApp_query$data = {
  readonly appEnv: string | null | undefined;
  readonly me: {
    readonly _id: string;
    readonly allRoles: ReadonlyArray<string | null | undefined>;
    readonly company: {
      readonly _id: string;
      readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly integrations: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly status: string | null | undefined;
            readonly type: IntegrationType | null | undefined;
          };
        } | null | undefined>;
      } | null | undefined;
      readonly lang: string | null | undefined;
      readonly name: string | null | undefined;
      readonly preferences: {
        readonly core: {
          readonly agreementTerm: {
            readonly active: boolean;
          };
        };
      };
      readonly projectType: string | null | undefined;
      readonly timezone: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"useCompanyRequiredFields_company">;
    } | null | undefined;
    readonly id: string;
    readonly isAdmin: boolean | null | undefined;
    readonly lang: string | null | undefined;
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CashSound_user" | "FeatureFlag_user" | "FeatureTemp_user" | "useChatwoot_user" | "useCrisp_user" | "usePermission_user">;
  } | null | undefined;
  readonly meMaster: {
    readonly " $fragmentSpreads": FragmentRefs<"useShouldChangePassword_userMaster" | "useUserRequiredFields_userMaster">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Header_query" | "Sidebar_query">;
  readonly " $fragmentType": "AdminApp_query";
};
export type AdminApp_query$key = {
  readonly " $data"?: AdminApp_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminApp_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "requiredFields",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allRoles",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameFriendly",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumbers",
  "storageKey": null
},
v13 = {
  "kind": "InlineDataFragmentSpread",
  "name": "usePermission_user",
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegrationConnection",
          "kind": "LinkedField",
          "name": "integrations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IntegrationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Integration",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filters"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminApp_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "IntegrationConnection",
              "kind": "LinkedField",
              "name": "integrations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IntegrationEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Integration",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timezone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyPreferences",
              "kind": "LinkedField",
              "name": "preferences",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompanyCorePreferences",
                  "kind": "LinkedField",
                  "name": "core",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompanyAgreementTermPreferences",
                      "kind": "LinkedField",
                      "name": "agreementTerm",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "active",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useCompanyRequiredFields_company",
              "selections": (v6/*: any*/),
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useCrisp_user",
          "selections": [
            (v9/*: any*/),
            (v4/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v11/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            (v12/*: any*/),
            (v13/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useChatwoot_user",
          "selections": [
            (v3/*: any*/),
            (v9/*: any*/),
            (v4/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                (v11/*: any*/)
              ],
              "storageKey": null
            },
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserMaster",
              "kind": "LinkedField",
              "name": "userMaster",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chatWootAuth",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeatureTemp_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CashSound_user"
        },
        (v13/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeatureFlag_user"
        },
        (v3/*: any*/),
        (v9/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMaster",
      "kind": "LinkedField",
      "name": "meMaster",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useShouldChangePassword_userMaster",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shouldChangePassword",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useUserRequiredFields_userMaster",
          "selections": (v6/*: any*/),
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Header_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Sidebar_query"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appEnv",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f77312894b6ce6afa146c9dc95726ae3";

export default node;
