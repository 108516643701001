import { useMemo } from 'react';
import { useSubscription } from 'react-relay';
import type { GraphQLSubscriptionConfig } from 'relay-runtime';

import type {
  ChargeCreatedSubscription,
  ChargeCreatedSubscription$variables,
} from './__generated__/ChargeCreatedSubscription.graphql.ts';
import { ChargeCreatedSubscription as ChargeCreated } from './ChargeCreatedSubscription.ts';

const useChargeCreatedSubscription = (
  variables: ChargeCreatedSubscription$variables,
) => {
  const newChargeCreated = useMemo<
    GraphQLSubscriptionConfig<ChargeCreatedSubscription>
  >(
    () => ({
      subscription: ChargeCreated,
      variables,
    }),
    [variables],
  );

  useSubscription(newChargeCreated);
};

export { useChargeCreatedSubscription };
