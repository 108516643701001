import type { Theme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
import styled from 'styled-components';
//import Divider from '@mui/material/Divider';

import { useSidebar } from './index.tsx';
import { MenuList } from './MenuList.tsx';

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  height: 60px;
  justify-content: center;
`;

export type ItemType = {
  items?: ItemType[];
  link: string;
  label: string;
  separator?: boolean;
  icon: React.ComponentType;
};

export type SidebarProps = {
  items: ItemType[];
  header?: React.ReactNode;
  projectType: string;
};

const PaperProps = {
  sx: {
    width: '240px',
  },
};

export const Sidebar = ({ items, header }: SidebarProps): ReactNode => {
  const { isSidebarOpen, sidebarClose } = useSidebar();
  const isTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('lg'),
  );
  const drawerType = isTablet ? 'temporary' : 'persistent';

  return (
    <Drawer
      variant={drawerType}
      open={isSidebarOpen}
      onClose={sidebarClose}
      PaperProps={PaperProps}
    >
      {header && <Header>{header}</Header>}
      {/* <Divider /> */}
      <MenuList items={items} />
    </Drawer>
  );
};
