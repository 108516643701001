/**
pnpm jest packages/service-console/src/modules/company/__tests__/CompanyConsoleQueries.spec.ts \
packages/service-console/src/modules/permissions/role/__tests__/RoleList.spec.ts \
packages/service-console/src/modules/company/mutations/console/__tests__/CompanyAddMutation.spec.ts \
packages/service-console/src/modules/company/mutations/console/__tests__/CompanyAddMutationWallet.spec.ts \
packages/service-console/src/modules/company/mutations/console/__tests__/CompanyAddWooviMutation.spec.ts -u

**/
export const MODULES = {
  ADJUST: 'ADJUST',
  API: 'API',
  BETA: 'BETA',
  BOLETO: 'BOLETO',
  CASHBACK: 'CASHBACK',
  CHARGE_INTENT: 'CHARGE_INTENT',
  CHATWOOT: 'CHATWOOT',
  CHECKOUT: 'CHECKOUT',
  CLICK_PIX: 'CLICK_PIX',
  CORE: 'CORE',
  CREDIT_CARD: 'CREDIT_CARD',
  CRYPTO_ACCOUNT: 'CRYPTO_ACCOUNT',
  DEFAULT_TIMEZONE: 'DEFAULT_TIMEZONE',
  DEMO_EVENT: 'DEMO_EVENT',
  DISCOUNT: 'DISCOUNT',
  FIDUCIA_MIGRATED: 'FIDUCIA_MIGRATED',
  INVOICE: 'INVOICE',
  ITP: 'ITP',
  ITP_RECIPIENT: 'ITP_RECIPIENT',
  LEDGER_BALANCE: 'LEDGER_BALANCE',
  DOUBLE_ENTRY: 'DOUBLE_ENTRY',
  META_PIXEL: 'META_PIXEL',
  MULTI_ACCOUNT: 'MULTI_ACCOUNT',
  NEW_PLATFORM_PLANS: 'NEW_PLATFORM_PLANS',
  OPEN_PIX: 'OPEN_PIX',
  OPENPIX_ADVANCED: 'OPENPIX_ADVANCED',
  PARTNER: 'PARTNER',
  PARTNER_FEE_MAX: 'PARTNER_FEE_MAX',
  PARTNER_WALLET: 'PARTNER_WALLET',
  PARTNER_AUTO_CREATE_WALLET: 'PARTNER_AUTO_CREATE_WALLET',
  PAY_BILLS: 'PAY_BILLS',
  PAY_PIX_KEY_DIRECT: 'PAY_PIX_KEY_DIRECT',
  PERMISSIONS: 'PERMISSIONS',
  PIX_CREDIARY: 'PIX_CREDIARY',
  PIX_CREDIT: 'PIX_CREDIT',
  PIX_OUT: 'PIX_OUT',
  PIXOUT_APPROVE_API: 'PIXOUT_APPROVE_API',
  PLANNING: 'PLANNING',
  ROLLOUT_NEW_CHARGE_CREATE: 'ROLLOUT_NEW_CHARGE_CREATE',
  ROLLOUT_NEW_CHARGE_CREATE_API: 'ROLLOUT_NEW_CHARGE_CREATE_API',
  ROLLOUT_OLD_CHARGE_CREATE: 'ROLLOUT_OLD_CHARGE_CREATE',
  ROLLOUT_SUBACCOUNT_WITHDRAW_MOVEMENT: 'ROLLOUT_SUBACCOUNT_WITHDRAW_MOVEMENT',
  ROLLOUT_SHOPIFY_AUTHORIZATION_EXPIRES:
    'ROLLOUT_SHOPIFY_AUTHORIZATION_EXPIRES',
  SHOPIFY_PAYMENT_SESSION_PENDING: 'SHOPIFY_PAYMENT_SESSION_PENDING',
  SPLIT_PARTNER: 'SPLIT_PARTNER',
  SPLIT_PAYMENT: 'SPLIT_PAYMENT',
  SPLIT_SUB_ACCOUNT: 'SPLIT_SUB_ACCOUNT',
  SUBACCOUNT_BLOCK_BALANCE: 'SUBACCOUNT_BLOCK_BALANCE',
  SUBSCRIPTION_NEW_CRON_ROLLOUT: 'SUBSCRIPTION_NEW_CRON_ROLLOUT',
  SUBSCRIPTION_PLAN: 'SUBSCRIPTION_PLAN',
  SUPPORT: 'SUPPORT',
  TEMP: 'TEMP',
  VTEX: 'VTEX',
  WHATSAPP_RECOVER_SALE: 'WHATSAPP_RECOVER_SALE',
  DARK_THEME_MODE: 'DARK_THEME_MODE',
} as const;
export type MODULES = (typeof MODULES)[keyof typeof MODULES];
export const ROLES = {
  ADMIN: {
    ALL: {
      ALL: 'ADMIN',
    },
  },
  ADJUST: {
    ALL: 'ADJUST::ALL',
  },
  API: {
    ALL: 'API::ALL',
    ADMIN: { ALL: 'API::ADMIN::ALL' },
  },
  CORE: {
    ALL: 'CORE::ALL',
    APPLICATION: 'CORE::APPLICATION',
    LOGIN: 'CORE::LOGIN',
    PREFERENCES: {
      CHANGE_EMAIL: 'CORE::PREFERENCES::CHANGE_EMAIL',
      CHANGE_JOB_TITLE: 'CORE::PREFERENCES::CHANGE_JOB_TITLE',
      CHANGE_MANAGER: 'CORE::PREFERENCES::CHANGE_MANAGER',
      CHANGE_NAME: 'CORE::PREFERENCES::CHANGE_NAME',
      CHANGE_NICKNAME: 'CORE::PREFERENCES::CHANGE_NICKNAME',
      CHANGE_PASSWORD: 'CORE::PREFERENCES::CHANGE_PASSWORD',
      CHANGE_TAXID: 'CORE::PREFERENCES::CHANGE_TAXID',
    },
    USER: {
      INVITE: 'CORE::USER::INVITE',
      MANAGE_TEAM: 'CORE::USER::MANAGE_TEAM',
    },
  },
  DISPUTE: {
    ALL: 'DISPUTE::ALL',
    VIEW: 'DISPUTE::VIEW',
  },
  OPEN_PIX: {
    ACCOUNT_BALANCE: 'OPEN_PIX::ACCOUNT_BALANCE',
    ACCOUNT_STATEMENT: 'OPEN_PIX::ACCOUNT_STATEMENT',
    ACCOUNT_WITHDRAW: 'OPEN_PIX::ACCOUNT_WITHDRAW',
    ADMIN: { ALL: 'OPEN_PIX::ADMIN::ALL' },
    ALL: 'OPEN_PIX::ALL',
    API_WEBHOOK: 'OPEN_PIX::API_WEBHOOK',
    CHARGE_CREATE: 'OPEN_PIX::CHARGE_CREATE',
    CHARGE_DELETE: 'OPEN_PIX::CHARGE_DELETE',
    CHARGE_EXPORT: 'OPEN_PIX::CHARGE_EXPORT',
    CHARGE_REPORT: 'OPEN_PIX::CHARGE_REPORT',
    CHARGE_SEE_ALL: 'OPEN_PIX::CHARGE_SEE_ALL',
    CHARGE_SEE_MINE: 'OPEN_PIX::CHARGE_SEE_MINE',
    CHARGE_VIEW: 'OPEN_PIX::CHARGE_VIEW',
    CHARGES: 'OPEN_PIX::CHARGES',
    CUSTOMER_EXPORT: 'OPEN_PIX::CUSTOMER_EXPORT',
    CUSTOMERS: 'OPEN_PIX::CUSTOMERS',
    DISPUTE_ALL: 'OPEN_PIX::DISPUTE_ALL',
    DISPUTE_VIEW: 'OPEN_PIX::DISPUTE_VIEW',
    PAYMENTS: 'OPEN_PIX::PAYMENTS',
    QRCODES: 'OPEN_PIX::QRCODES',
    REFUND: 'OPEN_PIX::REFUND',
    REPORT: 'OPEN_PIX::REPORT',
    SELLER: 'OPEN_PIX::SELLER',
    TRANSACTIONS: 'OPEN_PIX::TRANSACTIONS',
    TRANSACTIONS_EXPORT: 'OPEN_PIX::TRANSACTIONS_EXPORT',
    USER: { ALL: 'OPEN_PIX::USER::ALL' },
  },
  OPENPIX_ADVANCED: {
    ADMIN: { ALL: 'OPENPIX_ADVANCED::ADMIN::ALL' },
    ALL: 'OPENPIX_ADVANCED::ALL',
  },
  PARTNER: {
    ALL: 'PARTNER::ALL',
  },
  PERMISSIONS: {
    ALL: 'PERMISSIONS::ALL',
  },
  PIX_OUT: {
    BILL: {
      APPROVE: 'PIX_OUT::BILL::APPROVE',
      REQUEST: 'PIX_OUT::BILL::REQUEST',
      VIEW: 'PIX_OUT::BILL::VIEW',
    },
  },
  PLANNING: {
    ALL: 'PLANNING::ALL',
    COST_REVENUE_CENTER: { ALL: 'PLANNING::COST_REVENUE_CENTER::ALL' },
  },
  SUPPORT: {
    ALL: 'SUPPORT::ALL',
  },
};
export const ADMIN_USER_ADMIN = ROLES.ADMIN.ALL.ALL;
export const ADMIN_USER_DEFAULT_ROLES = [ROLES.ADMIN.ALL.ALL];
export const ADMIN_ROLE = ROLES.ADMIN.ALL.ALL;
// tests to update
// jest packages/service-main/src/modules/company/defaults/__tests__/CompanyDefaultRoleGroup.spec.ts
// jest packages/service-main/src/modules/company/mutations/console/__tests__/CompanyDefaults.spec.ts
// ROLES ENABLED ON DEFAULT COMPANY ROLE GROUP BASED ON MODULES
export const MODULE_DEFAULT_ROLES = {
  [MODULES.API]: [],
  [MODULES.CORE]: [
    ROLES.CORE.USER.MANAGE_TEAM,
    ROLES.CORE.USER.INVITE,
    ROLES.CORE.PREFERENCES.CHANGE_NAME,
    ROLES.CORE.PREFERENCES.CHANGE_MANAGER,
    ROLES.CORE.PREFERENCES.CHANGE_JOB_TITLE,
    ROLES.CORE.PREFERENCES.CHANGE_PASSWORD,
    ROLES.CORE.PREFERENCES.CHANGE_NICKNAME,
    ROLES.CORE.PREFERENCES.CHANGE_TAXID,
    ROLES.CORE.PREFERENCES.CHANGE_EMAIL,
  ],
  [MODULES.OPENPIX_ADVANCED]: [],
  [MODULES.OPEN_PIX]: [
    ROLES.OPEN_PIX.USER.ALL,
    ROLES.OPEN_PIX.REPORT,
    ROLES.OPEN_PIX.CHARGE_SEE_ALL,
  ],
  [MODULES.PERMISSIONS]: [],
  [MODULES.PLANNING]: [],
  [MODULES.SUPPORT]: [ROLES.SUPPORT.ALL],
  [MODULES.TEMP]: [],
} as const;
export const PRE_REGISTER_MODULES = [MODULES.PLANNING, MODULES.SUPPORT];

// default features
export const OPENPIX_MODULES = [
  MODULES.ADJUST,
  MODULES.CASHBACK,
  MODULES.CHARGE_INTENT,
  MODULES.CHATWOOT,
  MODULES.CHECKOUT,
  MODULES.LEDGER_BALANCE,
  MODULES.META_PIXEL,
  MODULES.NEW_PLATFORM_PLANS,
  MODULES.OPEN_PIX,
  MODULES.PAY_PIX_KEY_DIRECT,
  MODULES.PERMISSIONS,
  MODULES.SUBACCOUNT_BLOCK_BALANCE,
  MODULES.SUPPORT,
  MODULES.WHATSAPP_RECOVER_SALE,
  MODULES.DARK_THEME_MODE,
  MODULES.INVOICE,
];
export const COMPANY_PROJECT_TYPES_ENUM = {
  OPEN_PIX: 'OPEN_PIX',
  WOOVI: 'WOOVI',
} as const;
export const flatten = (arr: Array<string>): Array<string> =>
  arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    [],
  );
export const formatRole = (
  obj: Record<string, string>,
): Record<string, string> =>
  Object.values(obj).map((value) =>
    typeof value === 'string' ? value : formatRole(value),
  );
export const rolesAsArray = (): string[] => flatten(formatRole(ROLES));
export const CONSOLE_ROLES = {
  ACCOUNT_BLOCK_WITHDRAW: 'ACCOUNT::BLOCK_WITHDRAW',
  ACCOUNT_EDIT_FEE: 'ACCOUNT::EDIT::FEE',
  ACCOUNT_REGISTER_ALL: 'ACCOUNT_REGISTER::ALL',
  ACCOUNT_REGISTER_APPROVE: 'ACCOUNT_REGISTER::APPROVE',
  ACCOUNT_REGISTER_DELETE: 'ACCOUNT_REGISTER::DELETE',
  ACCOUNT_REGISTER_EDIT: 'ACCOUNT_REGISTER:EDIT',
  ACCOUNT_REGISTER_VERIFY: 'ACCOUNT_REGISTER::VERIFY',
  ADMIN: {
    ALL: {
      ALL: 'ADMIN',
    },
  },
  ADMIN_ROLE,
  COMPANY_ALL: 'COMPANY::ALL',
  CUSTOMER_SUCCESS: 'CUSTOMER_SUCCESS',
  DEVELOPERS: 'DEVELOPERS',
  LEAD_ALL: 'LEAD::ALL',
  SALES: 'SALES',
  TRUST_LIST_ALL: 'TRUST_LIST::ALL',
  USER_ALL: 'USER::ALL',
  USER_LOGIN: 'USER::LOGIN',
};
