import { graphql, useFragment } from 'react-relay';

import { COMPANY_PROJECT_TYPES_ENUM } from '@woovi/roles';
import { OpenPixLogoColor, WooviLogo } from '@woovi/ui';

import type { SidebarHeader_me$key } from './__generated__/SidebarHeader_me.graphql.ts';

import type { ReactNode } from 'react';

export type Props = {
  me: SidebarHeader_me$key;
};
const SidebarHeader = (props: Props): ReactNode => {
  const me = useFragment<SidebarHeader_me$key>(
    graphql`
      fragment SidebarHeader_me on User {
        company {
          projectType
        }
      }
    `,
    props.me,
  );

  if (me.company?.projectType === COMPANY_PROJECT_TYPES_ENUM.WOOVI) {
    return <WooviLogo height={'30px'} />;
  }

  return <OpenPixLogoColor height={'30px'} />;
};

export default SidebarHeader;
