import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/subscription';

export const subscriptionRoutes: RouteType[] = [
  {
    name: 'subscription.create',
    path: `${PATH}/create`,
    component: loadable(
      () =>
        import(
          '../../../components/subscription/create/SubscriptionCreate.tsx'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'subscription.plan.create',
    path: `${PATH}/plan/create`,
    component: loadable(
      () =>
        import(
          '../../../components/subscription/plan/create/SubscriptionPlanCreate.tsx'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.SUBSCRIPTION_PLAN],
  },
  {
    name: 'subscription',
    path: `${PATH}/tab`,
    labelHeader: ({ t }) => t('Subscriptions/Automatic Pix'),
    label: ({ t }) => t('Subscriptions/Automatic Pix'),
    component: loadable(
      () => import('../../../components/subscription/SubscriptionHeader.tsx'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'subscription.report',
        path: `${PATH}/tab/report`,
        labelHeader: ({ t }) => t('Report'),
        label: ({ t }) => t('Report'),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
        component: loadable(
          () =>
            import(
              '../../../components/subscription/report/SubscriptionReport.tsx'
            ),
        ),
      },
      {
        name: 'subscription.list',
        path: `${PATH}/tab/list`,
        labelHeader: ({ t }) => t('Subscriptions'),
        label: ({ t }) => t('Subscriptions'),
        component: loadable(
          () => import('../../../components/subscription/SubscriptionList.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'subscription.plan.list',
        path: `${PATH}/tab/plans`,
        labelHeader: ({ t }) => t('Plans'),
        label: ({ t }) => t('Plans'),
        component: loadable(
          () =>
            import(
              '../../../components/subscription/plan/SubscriptionPlanList.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.SUBSCRIPTION_PLAN],
      },
      {
        name: 'subscription.tab.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () =>
            import(
              '../../../components/subscription/tutorial/SubscriptionTutorialTab.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'subscription.automatic-pix.tutorial',
        path: `${PATH}/tab/automatic-pix`,
        labelHeader: ({ t }) => t('Automatic Pix'),
        label: ({ t }) => t('Automatic Pix'),
        component: loadable(
          () =>
            import(
              '../../../components/subscription/tutorial/SubscriptionTutorialAutomaticPix.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [],
      },
    ],
  },
  {
    name: 'subscription.tutorial',
    path: `${PATH}/tutorial`,
    labelHeader: ({ t }) => t('Tutorial'),
    label: ({ t }) => t('Tutorial'),
    component: loadable(
      () =>
        import(
          '../../../components/subscription/tutorial/SubscriptionTutorial.tsx'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [],
  },
  {
    name: 'subscription.detail',
    path: `${PATH}/detail/:id`,
    component: loadable(
      () =>
        import(
          '../../../components/subscription/detail/SubscriptionDetail.tsx'
        ),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Subscription'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'subscription.dashboard',
        path: `${PATH}/detail/:id/dashboard`,
        labelHeader: ({ t }) => t('Dashboard'),
        label: ({ t }) => t('Dashboard'),
        component: loadable(
          () =>
            import(
              '../../../components/subscription/detail/SubscriptionDetailDashboard.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'subscription.debug',
        path: `${PATH}/detail/:id/debug`,
        labelHeader: ({ t }) => t('Debug'),
        label: ({ t }) => t('Debug'),
        component: loadable(
          () =>
            import(
              '../../../components/subscription/detail/SubscriptionDetailDebug.tsx'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
    ],
  },
];
