import { isCPF } from 'brazilian-values';

import { onlyDigits } from './onlyDigits.ts';

/**
 * Pattern to match formatted CPF (999.999.999-99) or 11 numbers.
 */
// eslint-disable-next-line
export const CPF_PATTERN = /^(\d{11}|\d{3}\.\d{3}\.\d{3}\-\d{2})$/;

const NonNumeric = /\D/g;

const mapToNumeric = (value: string): string => value.replace(NonNumeric, '');

const padding = '00000000000';

const leftPad = (str: string) => {
  return padding.substring(0, padding.length - str.length) + str;
};

export const isSafeCPF = (value?: string): boolean => {
  if (!value) {
    return false;
  }

  if (!CPF_PATTERN.test(value)) {
    const onlyNumeric = mapToNumeric(value);
    const leftPadCpf = leftPad(onlyNumeric);

    return isCPF(leftPadCpf);
  }

  return isCPF(value);
};

export const isStrictCPF = (value: string): boolean => {
  const onlyDigitsValue = onlyDigits(value);

  if (!onlyDigitsValue) {
    return false;
  }

  return onlyDigitsValue?.length > 10 && isSafeCPF(value);
};
