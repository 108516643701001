import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/accounts';

export const accountsRoutes: RouteType[] = [
  {
    name: 'accounts.pix',
    path: `${PATH}`,
    labelHeader: ({ t }) => t('Pix'),
    label: ({ t }) => t('Pix'),
    component: loadable(
      () => import('../../../components/companyBankAccount/AccountTab.tsx'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.ACCOUNT_STATEMENT,
    ],
    requiredFeatures: [MODULES.ADJUST, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'accounts.pix.list',
        path: `${PATH}/list`,
        label: ({ t }) => t('Accounts'),
        component: loadable(
          () =>
            import('../../../components/companyBankAccount/AccountList.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.ACCOUNT_STATEMENT,
        ],
        requiredFeatures: [MODULES.ADJUST, MODULES.OPEN_PIX],
      },
      {
        name: 'account.subaccount.list',
        path: `${PATH}/subaccounts`,
        labelHeader: ({ t }) => t('Subaccounts'),
        label: ({ t }) => t('Subaccounts'),
        component: loadable(
          () => import('../../../components/subAccount/SubAccountList.tsx'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.ACCOUNT_STATEMENT,
        ],
        requiredFeatures: [
          {
            $and: [MODULES.ADJUST, MODULES.OPEN_PIX],
          },
        ],
      },
    ],
  },
  {
    name: 'accounts.subaccount.create',
    path: `/home/subaccount/create`,
    component: loadable(
      () => import('../../../components/subAccount/SubAccountCreate.tsx'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Create subaccount'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [
      {
        $and: [MODULES.ADJUST, MODULES.OPEN_PIX, MODULES.SPLIT_SUB_ACCOUNT],
      },
    ],
  },
  {
    name: 'subaccounts-details',
    path: `/home/subaccounts/details/:id`,
    labelHeader: ({ t }) => t('Details'),
    label: ({ t }) => t('Details'),
    component: loadable(
      () => import('../../../components/subAccount/SubAccountDetail.tsx'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [
      {
        $and: [MODULES.ADJUST, MODULES.OPEN_PIX, MODULES.SPLIT_SUB_ACCOUNT],
      },
    ],
  },
  {
    name: 'accounts.itp.new',
    path: `/home/account/itp/new`,
    component: loadable(
      () =>
        import('../../../components/companyBankAccount/itp/AccountITPNew.tsx'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Activate ITP account'),
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.ITP],
  },
  {
    name: 'accounts.edit',
    path: `${PATH}/edit`,
    component: loadable(
      () => import('../../../components/companyBankAccount/AccountEdit.tsx'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
  },
];

export default accountsRoutes;
