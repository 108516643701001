import invariant from 'invariant';
import { generatePath } from 'react-router-dom';

import { queryString } from '@woovi/shared';

import type { RouteName, RouteParamsType, RouteType } from './types.ts';

const findPathByRouteName = (
  name: string,
  routes: RouteType[],
): string | null | undefined =>
  routes
    .map((subroute) => {
      const { path, routes = [] } = subroute;

      if (subroute.name === name) {
        return path;
      }

      return findPathByRouteName(name, routes);
    })
    .filter((subroute) => subroute && subroute.length > 0)
    .shift();

export const getRouteTo = (routes: RouteType[]) => {
  invariant(
    Array.isArray(routes),
    'you should not use routeTo direct from @woovi/router',
  );

  return (
    name: RouteName,
    params: RouteParamsType = {},
    query?: Record<string, unknown>,
  ): string => {
    const path = findPathByRouteName(name, routes);

    if (!path) {
      invariant(!path, `name does not have a path: ${name} ${path}`);

      // return '/'
      return null;
    }

    const route = generatePath(path, params);

    if (query) {
      return `${route}?${queryString.stringify(query)}`;
    }

    return route;
  };
};

export default getRouteTo;
