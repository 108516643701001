import type { TFunction } from 'i18next';

type GetDisputeStatus = {
  [x: string]: string;
};

export const DISPUTE_STATUS = {
  IN_REVIEW: 'IN_REVIEW',
  OPENED: 'OPENED',
  PENDING: 'PENDING',
  PENDING_DOCUMENTATION: 'PENDING_DOCUMENTATION',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
};

export const DISPUTE_TYPE = {
  MED: 'MED',
  DISPUTE: 'DISPUTE',
  CHARGEBACK: 'CHARGEBACK',
};

export const getDisputeStatus = (t: TFunction): GetDisputeStatus => ({
  [DISPUTE_STATUS.IN_REVIEW]: t('In review'),
  [DISPUTE_STATUS.OPENED]: t('Open'),
  [DISPUTE_STATUS.PENDING]: t('Pending dispute'),
  [DISPUTE_STATUS.PENDING_DOCUMENTATION]: t('Pending Documentation'),
  [DISPUTE_STATUS.REJECTED]: t('Favorable to the company'),
  [DISPUTE_STATUS.CANCELED]: t('Canceled'),
  [DISPUTE_STATUS.ACCEPTED]: t('Dispute lost'),
  [DISPUTE_STATUS.ACKNOWLEDGED]: t('Acknowledged'),
});
